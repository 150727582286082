import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { COLORS, PINS } from "../config";

import { openStationShift, closeStationShift, updateStateStation } from "../utils/api";
import { getEmployeeCode, verificationManagerCode } from "../utils/utils";
import LoaderFull from "./Loader";

const INIT = { inputCode: { value: "", isInvalid: false }, employeeInput: null, isEmployeeInputInvalid: false };

export default function ManagerPopup({
  show,
  closeManagerPopup,
  station,
  stationState,
  isManagerPage,
  employeesFullList,
}) {
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState(INIT);

  const handleChange = (e) => {
    e.preventDefault();
    const customValue = e.target.getAttribute("data-handle");
    const value = e.target.value;
    if (customValue === "employeeInput") {
      setState((pS) => ({ ...pS, [customValue]: getEmployeeCode(value), isEmployeeInputInvalid: false }));
      return;
    }
    setState((pS) => ({ ...pS, [customValue]: { isInvalid: false, value: value } }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();  

    if (!state.employeeInput?.code) {
      setState((pS) => ({
        ...pS,
        isEmployeeInputInvalid: true,
      }));
      return;
    }

    if (!verificationManagerCode(state.inputCode.value, state.employeeInput)) {
      setState((pS) => ({
        ...pS,
        inputCode: { ...pS.inputCode, isInvalid: true },
      }));
      return;
    }

    if (show === "openStation") {
      setLoading(true);

      openStationShift(station)
        .then((r) => {
          console.log("OPENED STATION", r);
          const initOpenedStateStation = { ...stationState };
          const employeeOpenedStation = state.employeeInput;
          console.log("employeeOpenedStation", employeeOpenedStation);
          const getFullemplInfo = employeesFullList.find(({ name }) => name === employeeOpenedStation.name);
          if (getFullemplInfo) {
            const now = new Date();
            const hours = now.getHours();
            const minutes = now.getMinutes();
            initOpenedStateStation.activeEmployee = [
              {
                ...getFullemplInfo,
                in: `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`,
                out: "",
                minutes: 0,
                id: `${getFullemplInfo.value}_${0}`,
              },
            ];
          }
          updateStateStation(station, initOpenedStateStation)
            .then((r) => {
              console.log("UPDATED", r);
              window.location.reload();
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
    if (show === "closeStation") {
      setLoading(true);
      closeStationShift(station, stationState)
        .then((r) => {
          console.log("CLOSED STATION", r);
          window.location.reload();
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      {show && (
        <div className={`manager_popup ${isManagerPage ? "man_page_pop" : ""}`}>
          <LoaderFull show={loading} />

          <div className="block">
            <h2 className="fs_c_40">
              Please Verify for {show === "openStation" ? "Open" : "Close"} station{" "}
              <span style={{ textTransform: "capitalize" }}>"{station}"</span>
            </h2>

            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Code verify:</InputGroup.Text>
              <Form.Select
                className="fs_c_20 text-end"
                style={{ color: COLORS.Dropdown }}
                onChange={handleChange}
                data-handle="employeeInput"
                value={state.employeeInput?.name}
                isInvalid={state.isEmployeeInputInvalid}
              >
                <option style={{ fontSize: 12, color: "gray" }}>Select Employee</option>
                {PINS.map((em) => (
                  <option key={em.name} value={em.name}>
                    {em.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Required Choose Employee</Form.Control.Feedback>
            </InputGroup>

            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Control
                  className="fs_c_30"
                  type="password"
                  style={{ color: COLORS.NumberPad, textAlign: "center" }}
                  data-handle="inputCode"
                  autoComplete="new-password"
                  value={state.inputCode.value}
                  onChange={handleChange}
                  isInvalid={state.inputCode.isInvalid}
                />
                <Form.Control.Feedback type="invalid">Wrong Code</Form.Control.Feedback>
              </Form.Group>

              <Button
                type="submit"
                size="lg"
                className="fs_c_50 mt-5"
                style={{ padding: "15px 30px", fontSize: 30, margin: "0 auto" }}
              >
                Submit
              </Button>
            </Form>
          </div>
        </div>
      )}
    </>
  );
}
