import { Button, Form, InputGroup } from "react-bootstrap";
import { COLORS, PINS } from "../config";

import { verificationManagerCode, getEmployeeCode } from "../utils/utils";
import { useEffect, useRef, useState } from "react";

export default function ManagerInputForNextFlavor({ show, handleManager }) {
  // console.log("isStationClosed", isStationClosed);

  const [state, setState] = useState({
    inputCode: { value: "", isInvalid: false },
    employeeInput: null,
    isEmployeeInputInvalid: false,
  });
  const inputCodeRef = useRef("");

  const handleChange = (e) => {
    e.preventDefault();
    const customValue = e.target.getAttribute("data-handle");
    const value = e.target.value;

    if (customValue === "employeeInput") {
      setState((pS) => ({ ...pS, [customValue]: getEmployeeCode(value), isEmployeeInputInvalid: false }));
      return;
    }

    setState((pS) => ({ ...pS, [customValue]: { ...pS[customValue], value: value } }));
  };

  useEffect(() => {
    if (state.inputCode.value !== inputCodeRef.current && inputCodeRef.current !== "") {
      console.log("useef");
      setState((pS) => ({ ...pS, inputCode: { ...pS.inputCode, isInvalid: false } }));
      inputCodeRef.current = "";
    }
  }, [state]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const inputValue = form[0].value;

    inputCodeRef.current = state.inputCode.value;

    console.log(state, "submit", inputValue);

    if (!state.employeeInput?.code) {
      setState((pS) => ({
        ...pS,
        isEmployeeInputInvalid: true,
      }));
      return;
    }


    if (!verificationManagerCode(state.inputCode.value, state.employeeInput)) {
      setState((pS) => ({
        ...pS,
        inputCode: { ...pS.inputCode, isInvalid: true },
      }));
      return;
    }
    setState((pS) => ({
      ...pS,
      inputCode: { value: "", isInvalid: false },
    }));

    handleManager();
  };

  return (
    <>
      {show && (
        <div className="manager_popup">
          <div className="block">
            <h2 className="fs_c_40">Verify</h2>
            <h3 className="fs_c_20">For change flavor please input manager code</h3>

            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Code verify:</InputGroup.Text>
              <Form.Select
                aria-label="Default select example"
                className="fs_c_20 text-end"
                style={{ color: COLORS.Dropdown }}
                onChange={handleChange}
                data-handle="employeeInput"
                value={state.employeeInput?.name}
                isInvalid={state.isEmployeeInputInvalid}
              >
                <option style={{ fontSize: 12, color: "gray" }}>Select Employee</option>
                {PINS.map((em) => (
                  <option key={em.name} value={em.name}>
                    {em.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Required Choose Employee</Form.Control.Feedback>
            </InputGroup>

            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Control
                  className="fs_c_30"
                  type="password"
                  style={{ color: COLORS.NumberPad, textAlign: "center" }}
                  data-handle="inputCode"
                  value={state.inputCode.value}
                  onChange={handleChange}
                  isInvalid={state.inputCode.isInvalid}
                />
                <Form.Control.Feedback type="invalid">Wrong Code</Form.Control.Feedback>
              </Form.Group>

              <Button
                type="submit"
                size="lg"
                className="fs_c_50 mt-5"
                style={{ padding: "15px 30px", fontSize: 30, margin: "0 auto" }}
              >
                Submit
              </Button>
            </Form>
          </div>
        </div>
      )}
    </>
  );
}
