import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import { getEmployees } from "../../utils/api";

import ItemEmploye from "./EmployItem";

export default function EmployeCheck(props) {
  const [state, setState] = useState({ list: [] });

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const CURRENT_DATE = new Date().toLocaleDateString("en-US", options);

  useEffect(() => {
    getEmployees().then((employees) => {
      console.log("employees", employees);
      setState({ list: employees.list });
    });
  }, []);

  return (
    <>
      <Row className="mb-4 pb-4 mt-2">
        <Col>
          <div className="dateAsHeader">
            <p>Crew Station: {CURRENT_DATE}</p>
          </div>

          <ul className="employList">
            {state.list.map((item) => (
              <ItemEmploye key={item.id} item={item} />
            ))}
          </ul>
        </Col>
      </Row>
    </>
  );
}
