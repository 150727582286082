import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";

import { COLORS, bag_sizes, CODE_FLAVOR } from "../../../config";

export default function TabDataKitting({ handleChange, state }) {
  return (
    <>
      <Row className="mb-3">
        <Col xs={6}>
          <Form.Select
            onChange={handleChange}
            data-handle="flavor"
            disabled
            className="fs_c_20"
            style={{ color: COLORS.LabelData }}
            value={state.flavor}
          >
            <option style={{ fontSize: 12, color: "gray" }}>Choose flavor</option>
            {CODE_FLAVOR.map((em) => (
              <option key={em.contractions} value={em.code}>
                {em.name}
              </option>
            ))}
          </Form.Select>
        </Col>

        {state.flavor === "00" && (
          <Col>
            <InputGroup >
              <InputGroup.Text className="fs_c_20">Custom Flavor:</InputGroup.Text>
              <Form.Control
                className="fs_c_20 text-end"
                disabled
                type="text"
                style={{ color: COLORS.LabelData }}
                onChange={handleChange}
                value={state.customFlavor}
              />
            </InputGroup>
          </Col>
        )}
      </Row>
      <Row className="mb-3">
        <Col xs={5}>
          <InputGroup >
            <InputGroup.Text className="fs_c_20">Estimated Number of Bags:</InputGroup.Text>
            <Form.Control
              className="fs_c_30 text-end"
              type="number"
              style={{ color: COLORS.LabelData }}
              onChange={handleChange}
              data-handle1="flavors"
              data-handle3="estimatedNumberOfBags"
              data-handle4={state.id}
              value={state.estimatedNumberOfBags}
            />
          </InputGroup>
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text className="fs_c_20">Singles:</InputGroup.Text>
            <Form.Control
              className="fs_c_30 text-end"
              type="number"
              style={{ color: COLORS.LabelData }}
              onChange={handleChange}
              data-handle1="flavors"
              data-handle3="singles"
              data-handle4={state.id}
              value={state.singles}
            />
          </InputGroup>
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text className="fs_c_20">Refills:</InputGroup.Text>
            <Form.Control
              className="fs_c_30 text-end"
              type="number"
              style={{ color: COLORS.LabelData }}
              onChange={handleChange}
              data-handle1="flavors"
              data-handle3="refills"
              data-handle4={state.id}
              value={state.refills}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <InputGroup className="mb-2">
            <InputGroup.Text className="fs_c_20">Extra Bags:</InputGroup.Text>
            <Form.Control
              className="fs_c_30 text-end"
              type="text"
              style={{ color: COLORS.LabelData }}
              onChange={handleChange}
              data-handle1="flavors"
              data-handle3="extraBags"
              data-handle4={state.id}
              value={state.extraBags}
            />
          </InputGroup>
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text className="fs_c_20">Less Bags:</InputGroup.Text>
            <Form.Control
              className="fs_c_30 text-end"
              type="text"
              style={{ color: COLORS.LabelData }}
              onChange={handleChange}
              data-handle1="flavors"
              data-handle3="lessBags"
              data-handle4={state.id}
              value={state.lessBags}
            />
          </InputGroup>
        </Col>
      </Row>
    </>
  );
}
