import React from "react";
import ReactDOM from "react-dom/client";
import { Chart, registerables } from "chart.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tagsinput/react-tagsinput.css'
import "./index.css";
import "./media.css";

import App from "./containers/App/App";
import reportWebVitals from "./reportWebVitals";

Chart.register(...registerables);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
