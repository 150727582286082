import React, { useState, useEffect, useRef } from "react";
import { Row, InputGroup, Form, Tabs, Tab } from "react-bootstrap";

import { COLORS, MANAGER_INIT } from "../../../config";

import PopupActiveEmployee from "../../../helpers_components/PopupsStations/ActiveEmployee";
import PopupCleanUp from "../../../helpers_components/PopupsStations/CleanUp";
import PopupEnterNotes from "../../../helpers_components/PopupsStations/EnterNotes";

import TabDataSeasoningDailyLog from "./TabDataSeasoning";

import CommonFunctionality, { CalcParams } from "../../../utils/utils";
import LoaderFull from "../../../helpers_components/Loader";
import { ShiftManage } from "../../../helpers_components/ShiftManage";

export default function SeasoningDaily({ date, employees, number_of_batches_mode }) {
  const snapShotManagerState = useRef(MANAGER_INIT.seasoning);

  const [action, setAction] = useState("Flavor: 1");

  const [state, setState] = useState(MANAGER_INIT.seasoning);
  const [shiftState, setShiftState] = useState({ activeShift: 0, list: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    CommonFunctionality.getDataStationManager(
      "seasoning",
      snapShotManagerState,
      date,
      shiftState.activeShift,
      setLoading,
      setShiftState,
      setState
    );
  }, [date, shiftState.activeShift]);

  useEffect(() => {
    CommonFunctionality.updateStatePerChange(
      "seasoning",
      snapShotManagerState,
      state,
      setLoading,
      date,
      shiftState.activeShift
    );
  }, [state]);

  const handleChange = ({ target }) => {
    let attr1 = target.getAttribute("data-handle1") || target.parentNode.getAttribute("data-handle1");
    let attr2 = target.getAttribute("data-handle2") || target.parentNode.getAttribute("data-handle2");
    let attr3 = target.getAttribute("data-handle3") || target.parentNode.getAttribute("data-handle3");
    let attr4 = target.getAttribute("data-handle4") || target.parentNode.getAttribute("data-handle4");
    let attr5 = target.getAttribute("data-handle5") || target.parentNode.getAttribute("data-handle5");

    let value = target.value;

    console.log(attr1, attr2, attr3, attr4, attr5, value);

    if (attr1 === "flavors") {
      value = state.flavors.map((fl) => {
        if (fl.id === attr4) {
          if (value === "true" || value === "false") {
            value = JSON.parse(value);
          }

          if (attr3 === "seasoner" || attr3 === "bagger" || attr3 === "sealer") {
            if (attr2 === "delete") {
              return { ...fl, [attr3]: fl[attr3].filter(({ value }) => +value !== +attr5) };
            }
            const newSeso = employees.find((em) => +em.value === +value);
            return { ...fl, [attr3]: [...fl[attr3], newSeso] };
          }

          return { ...fl, [attr3]: attr2 === "clear" ? "" : value };
        }
        return fl;
      });
    }

    setState((pS) => ({
      ...pS,
      [attr1]: value,
    }));
  };

  const number_of_batches_used = CalcParams(state, "number_of_batches_used");
  const number_of_batches_remaining = (number_of_batches_mode - number_of_batches_used).toFixed(1);

  const handleSaveDataFromPopups = (type, data) => {
    console.log(type, data);
    setState((pS) => ({ ...pS, [type]: data }));
  };

  return (
    <>
      <div className="fs_c_40 text-center">
        Seasoning Station <span className="success_color">{state.isStationActive ? "Active" : "Not active"}</span>
      </div>

      <LoaderFull show={loading} />

      <ShiftManage
        createShift={null}
        shiftState={shiftState}
        setShiftState={setShiftState}
        handleChange={handleChange}
        isStationActive={state.isStationActive}
        deleteShift={() => {}}
      />
      {shiftState.list.length > 0 && (
        <>
          <Row className="">
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Number of Batches Used:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={number_of_batches_used}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Number of Batches Remaining:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={number_of_batches_remaining || ""}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Total Minutes:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={CalcParams(state, "totalMinutes")}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Number of Employees:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={CalcParams(state, "number_of_employees")}
              />
            </InputGroup>
          </Row>

          <h3 className="fs_c_30 text-center">Process</h3>

          <Row className="daily_tabs_block">
            <Tabs
              defaultActiveKey={action}
              id="uncontrolled-tab-example"
              onSelect={(k) => setAction(k)}
              activeKey={action}
              style={{ fontSize: 20, fontWeight: "bold" }}
            >
              {state.flavors.map((tab) => (
                <Tab key={tab.id} eventKey={tab.name} title={tab.name || ""} className="tab_container">
                  <TabDataSeasoningDailyLog employees={employees} state={tab} handleChangeFlavor={handleChange} />
                </Tab>
              ))}
            </Tabs>
          </Row>

          <PopupActiveEmployee
            asBlock={true}
            employeesFullList={employees}
            employeesListFromDB={state?.activeEmployee}
            handleSaveDataFromPopups={handleSaveDataFromPopups}
          />

          <PopupCleanUp
            asBlock={true}
            employeesFullList={employees}
            clean_ups={state?.clean_ups}
            handleSaveDataFromPopups={handleSaveDataFromPopups}
            station={"seasoning"}
          />

          <PopupEnterNotes
            asBlock={true}
            employeesFullList={employees}
            enter_notes={state?.enter_notes}
            handleSaveDataFromPopups={handleSaveDataFromPopups}
          />

          <Row>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Manager Notes for Seasoning:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="text"
                data-handle1="manager_notes"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.manager_notes || ""}
                onChange={handleChange}
              />
            </InputGroup>
          </Row>
        </>
      )}
    </>
  );
}
