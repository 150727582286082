import React, { useEffect, useState, useRef } from "react";
import { Row, InputGroup, Button, Form } from "react-bootstrap";

import { COLORS, MANAGER_INIT } from "../../../config";
import CommonFunctionality, { CalcParams } from "../../../utils/utils";
import PopupOilUsed from "../../../helpers_components/PopupsStations/OilUsed";
import PopupActiveEmployee from "../../../helpers_components/PopupsStations/ActiveEmployee";
import PopupCleanUp from "../../../helpers_components/PopupsStations/CleanUp";
import PopupEnterNotes from "../../../helpers_components/PopupsStations/EnterNotes";
import PopupFryers from "../../../helpers_components/PopupsStations/Fryers";
import { ShiftManage } from "../../../helpers_components/ShiftManage";
import LoaderFull from "../../../helpers_components/Loader";

export default function FryDaily({ employees, date }) {
  const snapShotManagerState = useRef(MANAGER_INIT.fry);

  const [state, setState] = useState(MANAGER_INIT.fry);
  const [shiftState, setShiftState] = useState({ activeShift: 0, list: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    CommonFunctionality.getDataStationManager(
      "fryer",
      snapShotManagerState,
      date,
      shiftState.activeShift,
      setLoading,
      setShiftState,
      setState
    );
  }, [date, shiftState.activeShift]);

  useEffect(() => {
    CommonFunctionality.updateStatePerChange(
      "fryer",
      snapShotManagerState,
      state,
      setLoading,
      date,
      shiftState.activeShift
    );
  }, [state]);

  const handleChange = ({ target }) => {
    const attr1 = target.getAttribute("data-handle1");
    const attr2 = target.getAttribute("data-handle2");

    let value = target.value;

    if (attr1.includes("verified")) {
      setState((pS) => ({
        ...pS,
        [attr1]: !pS[attr1],
      }));
      return;
    }

    setState((pS) => ({
      ...pS,
      [attr1]: attr2 === "clear" ? "" : value,
    }));
  };
  const handleSaveDataFromPopups = (type, data) => {
    console.log(type, data);
    setState((pS) => ({ ...pS, [type]: data }));
  };

  return (
    <>
      <div className="fs_c_40 text-center">
        Fry Station <span className="success_color">{state.isStationActive ? "Active" : "Not active"}</span>
      </div>

      <LoaderFull show={loading} />

      <ShiftManage
        createShift={null}
        shiftState={shiftState}
        setShiftState={setShiftState}
        handleChange={handleChange}
        isStationActive={state.isStationActive}
        deleteShift={() => {}}
      />

      {shiftState.list.length > 0 && (
        <>
          <Row className="">
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Pounds Fried:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={CalcParams(state, "pounds_fried")}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Pounds of Oil:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={CalcParams(state, "pounds_of_oil")}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Total Minutes:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={CalcParams(state, "totalMinutes")}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Number of Employees:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={CalcParams(state, "number_of_employees")}
              />
            </InputGroup>
          </Row>

          <h3 className="fs_c_30 text-center">Process</h3>

          <Row className="">
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">First Bucket In Fryer:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="time"
                data-handle1="firstBucketInFryerEmployee"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.firstBucketInFryerEmployee || ""}
                onChange={handleChange}
              />
              <Button
                data-handle1="firstBucketInFryerEmployee"
                data-handle2="clear"
                onClick={handleChange}
                style={{ padding: "0 5px" }}
              >
                Clear
              </Button>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Buckets In Fryer:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="text"
                data-handle1="buckets_in_fryer"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.buckets_in_fryer || ""}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Partial (Final ) Bucket Weight:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="text"
                data-handle1="partial_bucket_weight"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.partial_bucket_weight || ""}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Last Bucket Out Of Fryer:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="time"
                data-handle1="lastBucketOutFryerEmployee"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.lastBucketOutFryerEmployee || ""}
                onChange={handleChange}
              />
              <Button
                data-handle1="lastBucketOutFryerEmployee"
                data-handle2="clear"
                onClick={handleChange}
                style={{ padding: "0 5px" }}
              >
                Clear
              </Button>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Bucket Weight with Chips:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                data-handle1="bucket_weight_with_chips"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.bucket_weight_with_chips || ""}
                onChange={handleChange}
              />
              <Button
                onClick={handleChange}
                className="button_verify"
                data-handle="bucket_weight_with_chips_verified"
                variant={!state.bucket_weight_with_chips_verified ? "danger" : "light"}
              >
                {!state.bucket_weight_with_chips_verified ? "Verify" : "Verified"}
              </Button>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Empty Bucket Weight:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                data-handle1="empty_bucket_weight"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.empty_bucket_weight || ""}
                onChange={handleChange}
              />
              <Button
                onClick={handleChange}
                className="button_verify"
                data-handle="empty_bucket_weight_verified"
                variant={!state.empty_bucket_weight_verified ? "danger" : "light"}
              >
                {!state.empty_bucket_weight_verified ? "Verify" : "Verified"}
              </Button>
            </InputGroup>
          </Row>

          <PopupActiveEmployee
            asBlock={true}
            employeesFullList={employees}
            employeesListFromDB={state?.activeEmployee}
            handleSaveDataFromPopups={handleSaveDataFromPopups}
          />

          <PopupOilUsed
            asBlock={true}
            bucket_weight_oil={state?.bucket_weight_oil}
            oil_used={state?.oil_used}
            handleSaveDataFromPopups={handleSaveDataFromPopups}
            bucket_weight_oil_verified={state?.bucket_weight_oil_verified}
            handleChangeCustom={handleChange}
          />

          <PopupFryers
            asBlock={true}
            employeesFullList={employees}
            fryer_notes={state?.fryer_notes}
            fryers={state?.fryers}
            handleSaveDataFromPopups={handleSaveDataFromPopups}
          />

          <PopupCleanUp
            asBlock={true}
            employeesFullList={employees}
            clean_ups={state?.clean_ups}
            handleSaveDataFromPopups={handleSaveDataFromPopups}
            station={"fry"}
          />

          <PopupEnterNotes
            asBlock={true}
            employeesFullList={employees}
            enter_notes={state?.enter_notes}
            handleSaveDataFromPopups={handleSaveDataFromPopups}
          />

          <Row>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Manager Notes for Fry:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="text"
                data-handle1="manager_notes"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.manager_notes || ""}
                onChange={handleChange}
              />
            </InputGroup>
          </Row>
        </>
      )}
    </>
  );
}
