import { Spinner } from "react-bootstrap";

export default function LoaderFull({ show, stylesCustom }) {
  return null
  return (
    <>
      {show && (
        <div className="loaderfull" style={stylesCustom ? { ...stylesCustom } : {}}>
          <Spinner animation="border" variant="warning" size="lg" />
        </div>
      )}
    </>
  );
}
