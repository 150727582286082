import React, { useCallback, useEffect, useState } from "react";
import { Alert, Col, Form, InputGroup, Row, Label } from "react-bootstrap";
import { VariableSizeList as List } from "react-window";

import { debounce } from "lodash";

import Loader from "../Loader/Loader";
import Item_Fulfillment from "./Item";

import { getItems, getInventoryReceiving, getInventoryBuilds } from "../../utils/api";

import { getHeight } from "../../utils/utils";
import { useLocation } from "react-router-dom";

const COLUMNS = [
  "Item",
  "Description",
  "Quantity On Hand",
  "Received",
  "Sold",
  "History",
  "Last Modified",
  "Location",
];

const width = window.innerWidth;
export default function Inventory(props) {
  const [items, setItems] = useState([]);
  const [filtering, setFiltering] = useState([]);
  const [error, setError] = useState(null);

  const location = useLocation();

  function filteringItems(items, search) {
    const ff = items.filter(
      (ir) =>
        ir["Item"].toLowerCase().includes(search.toLowerCase()) ||
        ir["Description"].toLowerCase().includes(search.toLowerCase())
    );
    setFiltering(ff);
  }

  const debounceFn = useCallback(debounce(filteringItems, 1000), []);

  useEffect(() => {
    let funcGet = getItems;

    if (location.pathname === "/inventory-receiving") {
      funcGet = getInventoryReceiving;
    }
    if (location.pathname === "/inventory-builds") {
      funcGet = getInventoryBuilds;
    }

    funcGet().then((r) => {
      if (!r) return;
      setItems(r);
      setFiltering(r);
    });

    return () => {
      setItems([]);
      setFiltering([]);
    };
  }, [location]);

  const onSearch = (e) => {
    debounceFn(items, e.target.value);
  };

  const itemSize = (ind) => {
    let height = 70;
    const desc = filtering[ind]?.Description;

    if (desc) {
      if (desc.length > 100 && desc.length <= 150) height = 120;
      if (desc.length > 150 && desc.length <= 200) height = 150;
      if (desc.length > 200 && desc.length <= 250) height = 170;
      if (desc.length > 250 && desc.length <= 300) height = 190;
      if (desc.length > 300 && desc.length <= 350) height = 220;
      if (desc.length > 350) height = 250;
    }

    const loc = filtering[ind]?.location;

    if (loc) {
      const itemsLoc = loc.split(" ").length;
      if (itemsLoc > 3 && height < 120) {
        height = 120;
      }
    }

    return height;
  };

  return (
    <>
      {error && (
        <Alert variant="danger" className="row">
          {`${error.error && (error.error.msg || "") + " " + (error.error.code ? "(" + error.error.code + ")" : "")}`}
        </Alert>
      )}

      <Row className="mt-2">
        <Col>
          <>
            <div className="sticky-top" style={{ top: "64px" }}>
              <InputGroup className="mb-2">
                <Form.Control
                  type="text"
                  autoComplete="off"
                  // value={search}
                  className="fs_c_20"
                  id="search"
                  placeholder="Search"
                  onChange={onSearch}
                  onKeyDown={(e) => (e.key === "Enter" || e.code === 13 ? onSearch(e) : false)}
                />
                <InputGroup.Text id="basic-addon2">Items: {filtering.length}</InputGroup.Text>
              </InputGroup>
            </div>
            <ul className="head_sticky">
              {COLUMNS.map((liHead) => (
                <li key={liHead}>
                  <p>{liHead}</p>
                </li>
              ))}
            </ul>
            {items && items.length ? (
              <List
                className="container_list"
                useIsScrolling
                height={getHeight()}
                itemData={filtering}
                itemCount={filtering.length}
                itemSize={itemSize}
                width={"100%"}
              >
                {Item_Fulfillment}
              </List>
            ) : (
              <Loader />
            )}
          </>
        </Col>
      </Row>
    </>
  );
}
