import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { cloneDeep } from "lodash";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { COMMON_OPTIONS, chartData } from "../../../utils/utils";
import { getStateStationDashboard } from "../../../utils/api";
import LoaderFull from "../../../helpers_components/Loader";

const thead = ["Start Station", "Active Employee", "Break Employee", "Buckets", "Fryers", "Last Bucket", "End Station"];

export default function Fryer({ from, to }) {
  const [state, setState] = useState({
    loading: false,
    DATA: [],
  });

  useEffect(() => {
    setState({ ...state, loading: true });

    if (!from && !to) {
      getStateStationDashboard("fryer")
        .then((r) => {
          console.log(r);
          setState({ ...state, DATA: r.states, loading: false });
        })
        .catch((e) => {
          console.log(e);
          setState({ ...state, loading: false });
        });
    }

    if (!from || !to) {
      return;
    }

    getStateStationDashboard("fryer", from, to)
      .then((r) => {
        console.log(r);
        setState({ ...state, DATA: r.states, loading: false });
      })
      .catch((e) => {
        console.log(e);
        setState({ ...state, loading: false });
      });
  }, [from, to]);

  const recordsOptions = (records) => {
    const resp = cloneDeep(COMMON_OPTIONS(records));
    resp["plugins"] = {
      datalabels: {
        formatter: function (value, context) {
          return Math.floor(value);
          // return msToTime(Math.floor(value));
        },
        anchor: "end",
        clamp: true,
        clip: true,
        align: "top",
        offset: -5,
        font: { size: 10 },
      },
    };
    return resp;
  };

  return (
    <div className="dashboard_section">
      <h2 className="title">FRYER</h2>
      <div style={{ height: 300 }}>
        <Line data={chartData(state.DATA, "fryer")} options={recordsOptions(state.DATA)} plugins={[ChartDataLabels]} />
      </div>
      <div style={{ height: 400, overflow: "auto", position: "relative" }}>
        <LoaderFull show={state.loading} stylesCustom={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }} />

        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              {thead.map((th) => (
                <th key={th}>{th}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {state.DATA.map((s) => (
              <tr key={s.start}>
                <td>{s.start}</td>
                <td>{s.state.active_employee?.name}</td>
                <td>{s.state.break_employee?.name}</td>
                <td>{s.state.bucket_fryer}</td>
                <td>
                  {s.state.fryers && (
                    <ul className="list-unstyled d-flex">
                      {s.state.fryers.map((f) => (
                        <li key={f.fryer} style={{ border: "1px solid #fff", padding: 3, textAlign: "center" }}>
                          <p>Fryer_{f.fryer}</p>
                          <p>{f.temp}</p>
                        </li>
                      ))}
                    </ul>
                  )}
                </td>
                <td>{s.state.last_bucket}</td>
                <td>{s.end}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
