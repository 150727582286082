export function Status({ status, isLoading }) {
  let statusAct = "#ff0000";

  if (status < 10) {
    statusAct = "#DD2700";
  }

  if (status >= 10 && status < 25) {
    statusAct = "#F6EC00";
  }

  if (status >= 25) {
    statusAct = "#32C958";
  }

  return <div className={`status${isLoading ? " loading" : ""}`} style={{ backgroundColor: statusAct }}></div>;
}
