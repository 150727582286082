import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Button, Row, Alert, Badge, Table } from "react-bootstrap";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { COLORS, CODE_FLAVOR } from "../../../../config";

import { filterEmpl, validationCode } from "../../../../utils/utils";

export default function TabData({ employees, handleChange, state }) {
  const [isValidCode, setValidCode] = useState(false);

  useEffect(() => {
    setValidCode(validationCode(state.flavor, state.wheel_code));
  }, [state.wheel_code, state.flavor]);

  const flavor = CODE_FLAVOR.find(({ code }) => code === state.flavor);

  return (
    <>
      <h2
        className="text-center"
        style={{ fontSize: 80, fontWeight: "bolder", color: state.allergen ? "#ff0000" : "#00ff00" }}
      >
        {state.allergen ? "Allergen" : "Not Allergen"}
      </h2>

      <Row>
        <Col>
          <div className="fs_c_40">
            <span className="label_color">
              {flavor?.name} {state.flavor === "00" && <span className="label_color">{`(${state.customFlavor})`}</span>}
            </span>
          </div>
        </Col>
        <Col>
          <div className="fs_c_40">
            Batches: <span className="label_color">{state.batches}</span>
          </div>
        </Col>
        <Col>
          <div className="fs_c_40">
            Bag Size: <span className="label_color">{state.bag_size}</span>
          </div>
        </Col>
      </Row>

      <InputGroup className="mb-3 mt-4">
        <InputGroup.Text className="fs_c_20">Flavor Start Time:</InputGroup.Text>
        <Form.Control
          className="fs_c_30"
          type="time"
          style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
          onChange={handleChange}
          data-handle1="flavor_start_time"
          data-handle2={state.id}
          value={state.flavor_start_time}
        />
        <Button
          data-handle1="flavor_start_time"
          data-handle2={state.id}
          data-handle3="clear"
          onClick={handleChange}
          style={{ padding: "0 5px" }}
        >
          Clear
        </Button>
      </InputGroup>

      <Row>
        <div className="fs_c_30 mb-3 text-center">Batches completed</div>
        <div className="d-flex flex-row align-items-center mb-3 justify-content-center">
          <Button
            variant="primary"
            size="lg"
            className="mr-2 fs_c_100"
            style={{ marginRight: 30 }}
            data-handle1="increase_bucket"
            data-handle2={state.id}
            onClick={handleChange}
          >
            +
          </Button>
          <Button
            variant="primary"
            size="lg"
            className="fs_c_100"
            onClick={handleChange}
            data-handle1="decrease_bucket"
            data-handle2={state.id}
            style={{ paddingRight: 30, paddingLeft: 30 }}
          >
            -
          </Button>
          <Badge bg="#EE476F" className="fs_c_100">
            {state.batches_completed}
          </Badge>
        </div>
      </Row>

      <Row className="mb-4">
        <Col xs={6}>
          <InputGroup className="mb-2">
            <InputGroup.Text className="fs_c_20">Wheel Code:</InputGroup.Text>
            <InputMask
              mask="99-99999-a"
              maskChar={"X"}
              value={state.wheel_code}
              data-handle1="wheel_code"
              data-handle2={state.id}
              className="form-control input-color"
              style={{ fontSize: 30, fontWeight: 700, textAlign: "center", color: COLORS.Date_timePicker }}
              onChange={handleChange}
            >
              {(inputProps) => (
                <Form.Control
                  {...inputProps}
                  className="fs_c_30"
                  type={isValidCode ? "password" : "text"}
                  style={{ color: COLORS.NumberPad, textAlign: "center" }}
                  isInvalid={!isValidCode}
                  isValid={isValidCode}
                />
              )}
            </InputMask>
          </InputGroup>
          <InputGroup className="mb-2">
            <InputGroup.Text className="fs_c_20">Code Made by:</InputGroup.Text>
            <Form.Select
              className="fs_c_20 text-end"
              style={{ color: COLORS.Dropdown }}
              onChange={handleChange}
              data-handle1="code_made_by"
              data-handle2={state.id}
              value={state.code_made_by?.value}
            >
              <option style={{ fontSize: 12, color: "gray" }}>Select Employee</option>
              {employees.map((em) => (
                <option key={em.value} value={em.value}>
                  {em.name}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
          <InputGroup>
            <InputGroup.Text className="fs_c_20">Code Verified By:</InputGroup.Text>
            <Form.Select
              className="fs_c_20 text-end"
              style={{ color: COLORS.Dropdown }}
              onChange={handleChange}
              data-handle1="code_verified_by"
              data-handle2={state.id}
              value={state.code_verified_by?.value}
            >
              <option style={{ fontSize: 12, color: "gray" }}>Select Employee</option>
              {employees.map((em) => (
                <option key={em.value} value={em.value}>
                  {em.name}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
        </Col>
        <Col>
          <div className="mb-3">
            <h3>Seasoners:</h3>
            {state.seasoner && state.seasoner.length > 0 && (
              <ul style={{ width: "100%" }}>
                {state.seasoner.map((se) => (
                  <li
                    key={se.value}
                    className="d-flex align-items-center justify-content-between mb-1"
                    style={{ width: "100%" }}
                  >
                    <strong>{se.name}</strong>
                    <Button
                      style={{ width: 30, height: 30 }}
                      variant={"outline-danger"}
                      data-handle1="seasoner"
                      data-handle2={state.id}
                      data-handle3="delete"
                      data-handle4={se.value}
                      onClick={handleChange}
                      size="sm"
                    >
                      <FontAwesomeIcon
                        data-handle1="seasoner"
                        data-handle2={state.id}
                        data-handle3="delete"
                        data-handle4={se.value}
                        icon={faTrash}
                      />
                    </Button>
                  </li>
                ))}
              </ul>
            )}

            <Form.Select
              onChange={handleChange}
              data-handle1="seasoner"
              data-handle2={state.id}
              className="fs_c_30"
              style={{ color: COLORS.Dropdown }}
            >
              <option style={{ fontSize: 12, color: "gray" }}>Select Employee</option>
              {filterEmpl(employees, state.seasoner).map((em) => (
                <option key={em.value} value={em.value}>
                  {em.name}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="mb-3">
            <h3>Baggers:</h3>
            {state.bagger && state.bagger.length > 0 && (
              <ul style={{ width: "100%" }}>
                {state.bagger.map((se) => (
                  <li
                    key={se.value}
                    className="d-flex align-items-center justify-content-between mb-1"
                    style={{ width: "100%" }}
                  >
                    <strong>{se.name}</strong>
                    <Button
                      style={{ width: 30, height: 30 }}
                      variant={"outline-danger"}
                      data-handle1="bagger"
                      data-handle2={state.id}
                      data-handle3="delete"
                      data-handle4={se.value}
                      onClick={handleChange}
                      size="sm"
                    >
                      <FontAwesomeIcon
                        data-handle1="bagger"
                        data-handle2={state.id}
                        data-handle3="delete"
                        data-handle4={se.value}
                        icon={faTrash}
                      />
                    </Button>
                  </li>
                ))}
              </ul>
            )}

            <Form.Select
              onChange={handleChange}
              data-handle1="bagger"
              data-handle2={state.id}
              className="fs_c_30"
              style={{ color: COLORS.Dropdown }}
            >
              <option style={{ fontSize: 12, color: "gray" }}>Select Employee</option>
              {filterEmpl(employees, state.bagger).map((em) => (
                <option key={em.value} value={em.value}>
                  {em.name}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="mb-3">
            <h3>Sealers:</h3>
            {state.sealer && state.sealer.length > 0 && (
              <ul style={{ width: "100%" }}>
                {state.sealer.map((se) => (
                  <li
                    key={se.value}
                    className="d-flex align-items-center justify-content-between mb-1"
                    style={{ width: "100%" }}
                  >
                    <strong>{se.name}</strong>
                    <Button
                      style={{ width: 30, height: 30 }}
                      variant={"outline-danger"}
                      data-handle1="sealer"
                      data-handle2={state.id}
                      data-handle3="delete"
                      data-handle4={se.value}
                      onClick={handleChange}
                      size="sm"
                    >
                      <FontAwesomeIcon
                        data-handle1="sealer"
                        data-handle2={state.id}
                        data-handle3="delete"
                        data-handle4={se.value}
                        icon={faTrash}
                      />
                    </Button>
                  </li>
                ))}
              </ul>
            )}

            <Form.Select
              onChange={handleChange}
              data-handle1="sealer"
              data-handle2={state.id}
              className="fs_c_30"
              style={{ color: COLORS.Dropdown }}
            >
              <option style={{ fontSize: 12, color: "gray" }}>Select Employee</option>
              {filterEmpl(employees, []).map((em) => (
                <option key={em.value} value={em.value}>
                  {em.name}
                </option>
              ))}
            </Form.Select>
          </div>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col xs={6}>
          <InputGroup>
            <InputGroup.Text className="fs_c_20">Bags made:</InputGroup.Text>
            <Form.Control
              className="fs_c_30 text-end"
              type="number"
              style={{ color: COLORS.NumberPad }}
              onChange={handleChange}
              data-handle1="bags_mode"
              data-handle2={state.id}
              value={state.bags_mode}
            />
          </InputGroup>
        </Col>
        <Col xs={6}>
          <InputGroup>
            <InputGroup.Text className="fs_c_20">Lot Number</InputGroup.Text>
            <Form.Control
              className="fs_c_30 text-end"
              type="text"
              maxLength={15}
              style={{ color: COLORS.NumberPad }}
              onChange={handleChange}
              data-handle1="lot_number"
              data-handle2={state.id}
              value={state.lot_number}
            />
          </InputGroup>
        </Col>
      </Row>

      <Row>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Flavor End Time:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="time"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            onChange={handleChange}
            data-handle1="flavor_end_time"
            data-handle2={state.id}
            value={state.flavor_end_time}
          />
          <Button
            data-handle1="flavor_end_time"
            data-handle2={state.id}
            data-handle3="clear"
            onClick={handleChange}
            style={{ padding: "0 5px" }}
          >
            Clear
          </Button>
        </InputGroup>
      </Row>
    </>
  );
}
