import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Tabs, Tab, InputGroup, Form } from "react-bootstrap";

import { isDeepEqual, deepModify, getCurentDate, getCountData } from "../../utils/utils";
import { getEmployees, getSettings, updateSettings } from "../../utils/api";

import { COLORS, MANAGER_INIT, ADDED_PROP_EMPLOYEE } from "../../config";
import LoaderFull from "../../helpers_components/Loader";

import ManagerInfo from "./components/ManagerInfo";
import ImportExport from "./components/ImportExport";
import Raw from "./components/Raw";
import Fry from "./components/Fry";
import QA from "./components/QA";
import Seasoning from "./components/Seasoning";
import Kitting from "./components/Kitting";
import ProductionEmployee from "./components/ProductionEmployees";
import SKU_Manager from "./components/SKU_Manager";
import PopupAPIerror from "../../helpers_components/PopupsStations/ApiError";

const LIST_STATIONS = [
  { name: "Info", id: "manager_info" },
  { name: "Raw", id: "raw" },
  { name: "Fry", id: "fry" },
  { name: "QA", id: "qa" },
  { name: "Seasoning", id: "seasoning" },
  { name: "Kitting", id: "kitting" },
  { name: "Production Employees", id: "prod_empl_list" },
  { name: "Import Export", id: "import_export" },
  { name: "SKU MANAGER", id: "sku_manager" },
];

export default function Manager_Page() {
  const snapShotManagerState = useRef(MANAGER_INIT.managerInformation);
  const timerSendManagerState = useRef();

  const [employeesFullList, setEmployeesFullList] = useState([]);
  const [managerState, setManagerState] = useState(MANAGER_INIT.managerInformation);
  const [loading, setLoading] = useState(false);
  const [flavorsFromSeasoning, setFlavorsForKitting] = useState([]);
  const [shiftST, setShiftST] = useState({
    dateSelected: getCurentDate(),
    countedData: { raw: {}, fryer: {}, qa: {}, seasoning: {}, kitting: {} },
  });

  const [countData, setCountData] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);

    getEmployees()
      .then((emp) =>
        setEmployeesFullList(emp.list.map((e) => ({ value: e.id, name: e.first_name + " " + e.last_name })) || [])
      )
      .catch((e) => {
        console.log(e);
        setError(e);
      })
      .finally(() => setLoading(false));

    getSettings()
      .then((r) => {
        setManagerState((pS) => {
          const titi = deepModify(pS, r.settings);
          snapShotManagerState.current = titi;
          return titi;
        });
      })
      .catch((e) => {
        console.log(e);
        setError(e);
      });

    getCountData()
      .then((r) => {
        setCountData({ ...r });
      })
      .catch((e) => {
        console.log(e);
        setError(e);
      });
  }, []);

  useEffect(() => {
    if (snapShotManagerState.current) {
      if (!isDeepEqual(snapShotManagerState.current, managerState)) {
        clearTimeout(timerSendManagerState.current);
        timerSendManagerState.current = setTimeout(() => {
          onSubmitManager();
        }, 1000);
      }
    }
  }, [managerState]);

  const onSubmitManager = () => {
    setLoading(true);
    snapShotManagerState.current = managerState;
    updateSettings(managerState)
      .then((r) => console.log("UPDATED SUCCESSFULLY", r))
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };
  const handleTextField = ({ target }) => {
    const attr1 = target.getAttribute("data-handle1");
    setManagerState((pS) => ({
      ...pS,
      [attr1]: target.value,
    }));
  };
  const handleAssignedEmployees = ({ target }, newlist) => {
    let attr1 = target.getAttribute("data-handle1") || target.parentNode.getAttribute("data-handle1");
    let attr2 = target.getAttribute("data-handle2") || target.parentNode.getAttribute("data-handle2");
    let attr3 = target.getAttribute("data-handle3") || target.parentNode.getAttribute("data-handle3");
    let attr4 = target.getAttribute("data-handle4") || target.parentNode.getAttribute("data-handle4");

    if (!attr1 || !attr2 || !attr3) return;

    // console.log(attr1, attr2, attr3, attr4, target.value);

    if (attr3 === "select_new") {
      let newEmployee = employeesFullList.find((em) => +em.value === +target.value);

      // console.log("newEmployee", newEmployee);

      if (!newEmployee) {
        for (const emN of ADDED_PROP_EMPLOYEE) {
          if (emN.value === +target.value) {
            newEmployee = emN;
          }
        }
      }

      setManagerState((pS) => ({
        ...pS,
        [attr1]: {
          ...pS[attr1],
          [attr2]: [...pS[attr1][attr2], newEmployee],
        },
      }));
    }
    if (attr3 === "start_time") {
      setManagerState((pS) => ({
        ...pS,
        [attr1]: {
          ...pS[attr1],
          [attr2]: pS[attr1][attr2].map((empl) => {
            if (empl.value === +attr4) {
              return { ...empl, time: target.value };
            }
            return empl;
          }),
        },
      }));
    }
    if (attr3 === "clear_time") {
      setManagerState((pS) => ({
        ...pS,
        [attr1]: {
          ...pS[attr1],
          [attr2]: pS[attr1][attr2].map((empl) => {
            if (empl.value === +attr4) {
              return { ...empl, time: "" };
            }
            return empl;
          }),
        },
      }));
    }
    if (attr3 === "delete_empl") {
      setManagerState((pS) => ({
        ...pS,
        [attr1]: {
          ...pS[attr1],
          [attr2]: pS[attr1][attr2].filter((empl) => empl.value !== +attr4),
        },
      }));
    }

    if (attr3 === "change_order") {
      setManagerState((pS) => ({
        ...pS,
        [attr1]: {
          ...pS[attr1],
          [attr2]: newlist,
        },
      }));
    }
  };

  return (
    <div className="position-relative mb-6">
      <LoaderFull show={loading} />
      {error && <PopupAPIerror text={error?.msg} closeModal={() => setError(null)} />}

      <Row className="justify-content-center align-items-center">
        <Col xs={6}>
          <InputGroup>
            <InputGroup.Text className="fs_c_30">Date Select:</InputGroup.Text>
            <Form.Control
              className="fs_c_30"
              type="date"
              min={getCurentDate()}
              style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
              onChange={({ target }) => {
                setShiftST((pS) => ({
                  ...pS,
                  dateSelected: target.value,
                }));
              }}
              value={shiftST.dateSelected}
            />
          </InputGroup>
        </Col>
      </Row>

      {/* <Button onClick={TEST}>TEST</Button> */}

      <Tabs
        id="uncontrolled-tab-example"
        transition={false}
        fill={false}
        justify={true}
        style={{ fontWeight: "bold", fontSize: 20 }}
      >
        {LIST_STATIONS.map((tab, ind) => (
          <Tab key={tab.id} eventKey={tab.id} title={tab.name} className="tab_container ss">
            {tab.id === "manager_info" && (
              <ManagerInfo managerState={managerState} handleTextField={handleTextField} countData={countData} />
            )}
            {tab.id === "import_export" && <ImportExport />}
            {tab.id === "raw" && (
              <Raw employees={managerState.productionEmployee.assignedEmployees} date={shiftST.dateSelected} />
            )}
            {tab.id === "fry" && (
              <Fry employees={managerState.productionEmployee.assignedEmployees} date={shiftST.dateSelected} />
            )}
            {tab.id === "qa" && (
              <QA employees={managerState.productionEmployee.assignedEmployees} date={shiftST.dateSelected} />
            )}
            {tab.id === "seasoning" && (
              <Seasoning
                employees={managerState.productionEmployee.assignedEmployees}
                setFlavorsForKitting={setFlavorsForKitting}
                date={shiftST.dateSelected}
              />
            )}
            {tab.id === "kitting" && (
              <Kitting
                employees={managerState.productionEmployee.assignedEmployees}
                flavorsFromSeasoning={flavorsFromSeasoning}
                date={shiftST.dateSelected}
              />
            )}
            {tab.id === "prod_empl_list" && (
              <ProductionEmployee
                managerState={managerState}
                employees={employeesFullList}
                handleAssignedEmployees={handleAssignedEmployees}
              />
            )}
            {tab.id === "sku_manager" && <SKU_Manager setError={setError} />}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}
