import React from "react";
import { Form, InputGroup, Button, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../config";

import { filterEmpl } from "../../../utils/utils";

export default function TabDataSeasoningDailyLog({ employees, handleChangeFlavor, state }) {
  return (
    <>
      <Row className="">
        <InputGroup className="mb-2">
          <InputGroup.Text className="fs_c_20">Flavor Start Time:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="time"
            style={{ color: COLORS.LabelData }}
            onChange={handleChangeFlavor}
            data-handle1="flavors"
            data-handle3="flavor_start_time"
            data-handle4={state.id}
            value={state.flavor_start_time}
          />
          <Button
            data-handle1="flavors"
            data-handle3="flavor_start_time"
            data-handle2="clear"
            data-handle4={state.id}
            onClick={handleChangeFlavor}
            style={{ padding: "0 5px" }}
          >
            Clear
          </Button>
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroup.Text className="fs_c_20">Batches Completed:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="number"
            style={{ color: COLORS.LabelData }}
            onChange={handleChangeFlavor}
            data-handle1="flavors"
            data-handle3="batches_completed"
            data-handle4={state.id}
            value={state.batches_completed}
          />
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroup.Text className="fs_c_20">Wheel Code:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="text"
            style={{ color: COLORS.LabelData }}
            onChange={handleChangeFlavor}
            data-handle1="flavors"
            data-handle3="wheel_code"
            data-handle4={state.id}
            value={state.wheel_code}
          />
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroup.Text className="fs_c_20">Code Made by:</InputGroup.Text>
          <Form.Select
            className="fs_c_20 text-end"
            style={{ color: COLORS.Dropdown }}
            onChange={handleChangeFlavor}
            data-handle1="flavors"
            data-handle3="code_made_by"
            data-handle4={state.id}
            value={state.code_made_by?.value}
          >
            <option style={{ fontSize: 12, color: "gray" }}>Select Employee</option>
            {employees &&
              employees.map((em) => (
                <option key={em.value} value={em.value}>
                  {em.name}
                </option>
              ))}
          </Form.Select>
        </InputGroup>
        <InputGroup>
          <InputGroup.Text className="fs_c_20">Code Verified By:</InputGroup.Text>
          <Form.Select
            className="fs_c_20 text-end"
            style={{ color: COLORS.Dropdown }}
            onChange={handleChangeFlavor}
            data-handle1="flavors"
            data-handle3="code_verified_by"
            data-handle4={state.id}
            value={state.code_verified_by?.value}
          >
            <option style={{ fontSize: 12, color: "gray" }}>Select Employee</option>
            {employees &&
              employees.map((em) => (
                <option key={em.value} value={em.value}>
                  {em.name}
                </option>
              ))}
          </Form.Select>
        </InputGroup>
        <div className="mb-3">
          <h3>Seasoners:</h3>
          {state.seasoner && state.seasoner.length > 0 && (
            <ul style={{ width: "100%" }}>
              {state.seasoner.map((se) => (
                <li
                  key={se.value}
                  className="d-flex align-items-center justify-content-between mb-1"
                  style={{ width: "100%" }}
                >
                  <strong>{se.name}</strong>
                  <Button
                    style={{ width: 30, height: 30 }}
                    variant={"outline-danger"}
                    data-handle1="flavors"
                    data-handle3="seasoner"
                    data-handle4={state.id}
                    data-handle2="delete"
                    data-handle5={se.value}
                    onClick={handleChangeFlavor}
                    size="sm"
                  >
                    <FontAwesomeIcon
                      data-handle1="flavors"
                      data-handle3="seasoner"
                      data-handle4={state.id}
                      data-handle2="delete"
                      data-handle5={se.value}
                      icon={faTrash}
                    />
                  </Button>
                </li>
              ))}
            </ul>
          )}

          <Form.Select
            onChange={handleChangeFlavor}
            data-handle1="flavors"
            data-handle2="add_new"
            data-handle3="seasoner"
            data-handle4={state.id}
            className="fs_c_30"
            style={{ color: COLORS.Dropdown }}
          >
            <option style={{ fontSize: 12, color: "gray" }}>Select Employee</option>
            {filterEmpl(employees, state.seasoner).map((em) => (
              <option key={em.value} value={em.value}>
                {em.name}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="mb-3">
          <h3>Baggers:</h3>
          {state.bagger && state.bagger.length > 0 && (
            <ul style={{ width: "100%" }}>
              {state.bagger.map((se) => (
                <li
                  key={se.value}
                  className="d-flex align-items-center justify-content-between mb-1"
                  style={{ width: "100%" }}
                >
                  <strong>{se.name}</strong>
                  <Button
                    style={{ width: 30, height: 30 }}
                    variant={"outline-danger"}
                    data-handle1="flavors"
                    data-handle3="bagger"
                    data-handle4={state.id}
                    data-handle2="delete"
                    data-handle5={se.value}
                    onClick={handleChangeFlavor}
                    size="sm"
                  >
                    <FontAwesomeIcon
                      data-handle1="flavors"
                      data-handle3="bagger"
                      data-handle4={state.id}
                      data-handle2="delete"
                      data-handle5={se.value}
                      icon={faTrash}
                    />
                  </Button>
                </li>
              ))}
            </ul>
          )}

          <Form.Select
            onChange={handleChangeFlavor}
            data-handle1="flavors"
            data-handle2="add_new"
            data-handle3="bagger"
            data-handle4={state.id}
            className="fs_c_30"
            style={{ color: COLORS.Dropdown }}
          >
            <option style={{ fontSize: 12, color: "gray" }}>Select Employee</option>
            {filterEmpl(employees, state.bagger).map((em) => (
              <option key={em.value} value={em.value}>
                {em.name}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="mb-3">
          <h3>Sealers:</h3>
          {state.sealer && state.sealer.length > 0 && (
            <ul style={{ width: "100%" }}>
              {state.sealer.map((se) => (
                <li
                  key={se.value}
                  className="d-flex align-items-center justify-content-between mb-1"
                  style={{ width: "100%" }}
                >
                  <strong>{se.name}</strong>
                  <Button
                    style={{ width: 30, height: 30 }}
                    variant={"outline-danger"}
                    data-handle1="flavors"
                    data-handle3="sealer"
                    data-handle4={state.id}
                    data-handle2="delete"
                    data-handle5={se.value}
                    onClick={handleChangeFlavor}
                    size="sm"
                  >
                    <FontAwesomeIcon
                      data-handle1="flavors"
                      data-handle3="sealer"
                      data-handle4={state.id}
                      data-handle2="delete"
                      data-handle5={se.value}
                      icon={faTrash}
                    />
                  </Button>
                </li>
              ))}
            </ul>
          )}

          <Form.Select
            onChange={handleChangeFlavor}
            data-handle1="flavors"
            data-handle2="add_new"
            data-handle3="sealer"
            data-handle4={state.id}
            className="fs_c_30"
            style={{ color: COLORS.Dropdown }}
          >
            <option style={{ fontSize: 12, color: "gray" }}>Select Employee</option>
            {filterEmpl(employees, state.sealer).map((em) => (
              <option key={em.value} value={em.value}>
                {em.name}
              </option>
            ))}
          </Form.Select>
        </div>
        <InputGroup className="mb-2">
          <InputGroup.Text className="fs_c_20">Bags Made:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="text"
            style={{ color: COLORS.LabelData }}
            onChange={handleChangeFlavor}
            data-handle1="flavors"
            data-handle3="bags_mode"
            data-handle4={state.id}
            value={state.bags_mode}
          />
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroup.Text className="fs_c_20">Lot Number:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="text"
            style={{ color: COLORS.LabelData }}
            onChange={handleChangeFlavor}
            data-handle1="flavors"
            data-handle3="lot_number"
            data-handle4={state.id}
            value={state.lot_number}
          />
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroup.Text className="fs_c_20">Flavor End Time:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="time"
            style={{ color: COLORS.LabelData }}
            onChange={handleChangeFlavor}
            data-handle1="flavors"
            data-handle3="flavor_end_time"
            data-handle4={state.id}
            value={state.flavor_end_time}
          />
          <Button
            data-handle1="flavors"
            data-handle3="flavor_end_time"
            data-handle2="clear"
            data-handle4={state.id}
            onClick={handleChangeFlavor}
            style={{ padding: "0 5px" }}
          >
            Clear
          </Button>
        </InputGroup>
      </Row>
    </>
  );
}
