import React, { useState, useEffect } from "react";
import { Table, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

import { COLORS, clean_ups_stations, propToName, HEAD_TABLE_CLEAN_UP } from "../../config";

export default function PopupCleanUp({
  asBlock,
  station,
  clean_ups,
  employeesFullList,
  closeModal,
  handleSaveDataFromPopups,
}) {
  const [clean_ups_st, set_clean_ups_st] = useState(clean_ups || {});

  useEffect(() => {
    set_clean_ups_st(clean_ups);
  }, [clean_ups]);

  const handleChange = ({ target }) => {
    let attr1 = target.getAttribute("data-handle1");
    let attr2 = target.getAttribute("data-handle2");
    if (!attr1) {
      attr1 = target.parentNode.getAttribute("data-handle1");
    }
    if (!attr2) {
      attr2 = target.parentNode.getAttribute("data-handle2");
    }

    let value = target.value;

    console.log(attr1, attr2, value);

    if (attr1 === "add_new") {
      const getEmpl = employeesFullList.find((em) => +em.value === +value);

      console.log("getEmpl", getEmpl);
      set_clean_ups_st((pS) => ({ ...pS, [attr2]: getEmpl }));
    }
  };

  const saveChanges = () => {
    handleSaveDataFromPopups("clean_ups", clean_ups_st);
    !asBlock && closeModal();
  };

  return (
    <div className={asBlock ? "" : "popup_container"}>
      <div className={`block ${asBlock ? "daily" : ""}`}>
        {!asBlock && (
          <div className="close_button" onClick={closeModal}>
            <FontAwesomeIcon icon={faCircleXmark} size="2xl" style={{ color: "#511f2e", padding: 10 }} />
          </div>
        )}
        <div className="fs_c_30 text-center">Clean Up</div>

        <Table bordered hover>
          <thead>
            <tr>
              {HEAD_TABLE_CLEAN_UP.map((th) => (
                <th style={{ textAlign: "center", fontSize: 25 }} key={th}>
                  {th}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {clean_ups_stations[station].map((st) => (
              <tr key={st}>
                <td className="clean_ups_title">{propToName(st)}</td>
                <td>
                  <Form.Select
                    onChange={handleChange}
                    data-handle1="add_new"
                    data-handle2={st}
                    className="fs_c_20"
                    style={{ color: COLORS.Dropdown }}
                    value={clean_ups_st?.[st]?.value}
                  >
                    <option style={{ fontSize: 12, color: "gray" }}>Select Employee</option>
                    {employeesFullList &&
                      employeesFullList.map((em) => (
                        <option key={em.value} value={em.value}>
                          {em.name}
                        </option>
                      ))}
                  </Form.Select>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* {!asBlock && ( */}
        <Button
          size="lg"
          className={asBlock ? "" : `save_button mt-3`}
          style={{ padding: "10px 20px" }}
          onClick={saveChanges}
        >
          Save Clean Up
        </Button>
        {/* )} */}
      </div>
    </div>
  );
}
