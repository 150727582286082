import React, { useEffect, useState, useRef } from "react";
import { Row, InputGroup, Button, Form, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import TabDataKitting from "./TabDataKitting";
import CommonFunctionality, { filterEmpl } from "../../../utils/utils";
import { COLORS, MANAGER_INIT, tamplateFlavorKitting } from "../../../config";

import PopupStationNotActive from "../../../helpers_components/PopupsStations/StationNotActive";
import LoaderFull from "../../../helpers_components/Loader";
import { ShiftManage } from "../../../helpers_components/ShiftManage";

export default function Kitting({ employees, flavorsFromSeasoning, date }) {
  const snapShotManagerState = useRef(MANAGER_INIT.kitting);
  const [action, setAction] = useState("Flavor: 1");
  const [state, setState] = useState(MANAGER_INIT.kitting);

  const [shiftState, setShiftState] = useState({ activeShift: 0, list: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    CommonFunctionality.getDataStationManager(
      "kitting",
      snapShotManagerState,
      date,
      shiftState.activeShift,
      setLoading,
      setShiftState,
      setState
    );
  }, [date, shiftState.activeShift]);
  useEffect(() => {
    console.log("flavorsFromSeasoning", flavorsFromSeasoning);
    if (!flavorsFromSeasoning || flavorsFromSeasoning.length === 0) return;

    setState((pS) => ({
      ...pS,
      flavors: flavorsFromSeasoning.map((fl) => {
        const kitFl = pS.flavors.find((kitfl) => kitfl.id === fl.id);

        if (kitFl) {
          return {
            ...kitFl,
            id: fl.id,
            name: fl.name,
            flavor: fl.flavor,
            customFlavor: fl.customFlavor,
            bag_size: fl.bag_size,
          };
        } else {
          return {
            ...tamplateFlavorKitting,
            id: fl.id,
            name: fl.name,
            flavor: fl.flavor,
            customFlavor: fl.customFlavor,
            bag_size: fl.bag_size,
          };
        }
      }),
    }));
  }, [flavorsFromSeasoning]);
  useEffect(() => {
    CommonFunctionality.updateStatePerChange(
      "kitting",
      snapShotManagerState,
      state,
      setLoading,
      date,
      shiftState.activeShift
    );
  }, [state]);

  const handleChange = ({ target }) => {
    const attr1 = target.getAttribute("data-handle1");
    let attr3 = target.getAttribute("data-handle3");
    let attr4 = target.getAttribute("data-handle4");
    if (!attr3) attr3 = target.parentNode.getAttribute("data-handle3");
    if (!attr4) attr4 = target.parentNode.getAttribute("data-handle4");

    let value = target.value;
    if (value === "on") {
      value = target.checked;
    }
    // console.log(attr1, attr3, attr4, value);

    if (attr1 === "assignedEmployees") {
      if (attr3 === "select_new") {
        const newEmployee = employees.find((em) => +em.value === +value);
        value = [...state.assignedEmployees, newEmployee];
      }
      if (attr3 === "start_time") {
        value = state.assignedEmployees.map((empl) => {
          if (empl.value === +attr4) return { ...empl, time: value };
          return empl;
        });
      }
      if (attr3 === "clear_time") {
        value = state.assignedEmployees.map((empl) => {
          if (empl.value === +attr4) return { ...empl, time: "" };
          return empl;
        });
      }
      if (attr3 === "delete_empl") {
        value = state.assignedEmployees.filter((empl) => empl.value !== +attr4);
      }
    }

    if (attr1 === "flavors") {
      value = state.flavors.map((fl) => {
        if (fl.id === attr4) {
          if (value === "true" || value === "false") {
            value = JSON.parse(value);
          }

          return { ...fl, [attr3]: value };
        }
        return fl;
      });
    }

    setState((pS) => ({
      ...pS,
      [attr1]: value,
    }));
  };

  const createShift = () => {
    CommonFunctionality.createShift(
      "kitting",
      snapShotManagerState,
      date,
      shiftState.activeShift,
      setLoading,
      setShiftState,
      setState
    );
  };
  const deleteShift = (shiftId) => {
    CommonFunctionality.deleteShift(
      "kitting",
      shiftId,
      snapShotManagerState,
      date,
      shiftState.activeShift,
      setLoading,
      setShiftState,
      setState
    );
  };

  return (
    <Row className="position-relative">
      <LoaderFull show={loading} />
      <ShiftManage
        createShift={createShift}
        shiftState={shiftState}
        setShiftState={setShiftState}
        handleChange={handleChange}
        isStationActive={state.isStationActive}
        deleteShift={deleteShift}
      />
      <Row className="position-relative">
        {!state.isStationActive && <PopupStationNotActive isManagerPage={true} />}
        {shiftState.end && <PopupStationNotActive isManagerPage={true} text={"Shift has already Closed"} />}

        <Row>
          <div className="mb-3">
            {state.assignedEmployees.length > 0 && (
              <ul
                className="list-unstyled d-flex flex-column align-items-center justify-content-center"
                style={{ width: "100%" }}
              >
                {state.assignedEmployees.map((se) => (
                  <li
                    key={se.value}
                    className="d-flex align-items-center justify-content-between mb-1"
                    style={{ width: "80%" }}
                  >
                    <strong style={{ fontSize: 25 }}>{se.name}</strong>

                    <InputGroup style={{ width: "70%" }}>
                      <InputGroup.Text className="fs_c_20">Start Time:</InputGroup.Text>
                      <Form.Control
                        className="fs_c_30"
                        type="time"
                        style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
                        onChange={handleChange}
                        data-handle1="assignedEmployees"
                        data-handle3="start_time"
                        data-handle4={se.value}
                        value={se.time || ""}
                      />
                      <Button
                        data-handle1="assignedEmployees"
                        data-handle3="clear_time"
                        data-handle4={se.value}
                        onClick={handleChange}
                        style={{ padding: "0 5px" }}
                      >
                        Clear
                      </Button>
                      <Button
                        style={{ marginLeft: 30, width: 50 }}
                        variant={"outline-danger"}
                        data-handle1="assignedEmployees"
                        data-handle3="delete_empl"
                        data-handle4={se.value}
                        onClick={handleChange}
                        size="sm"
                      >
                        <FontAwesomeIcon
                          size="lg"
                          data-handle1="assignedEmployees"
                          data-handle3="delete_empl"
                          data-handle4={se.value}
                          icon={faTrash}
                        />
                      </Button>
                    </InputGroup>
                  </li>
                ))}
              </ul>
            )}

            <Form.Select
              onChange={handleChange}
              data-handle1="assignedEmployees"
              data-handle3="select_new"
              className="fs_c_30"
              style={{ color: COLORS.Dropdown }}
            >
              <option style={{ fontSize: 12, color: "gray" }}>Select Employee</option>
              {filterEmpl(employees, state.assignedEmployees).map((em) => (
                <option key={em.value} value={em.value}>
                  {em.name}
                </option>
              ))}
            </Form.Select>
          </div>
        </Row>

        <Tabs
          defaultActiveKey={action}
          id="uncontrolled-tab-example"
          onSelect={(k) => setAction(k)}
          activeKey={action}
          style={{ fontSize: 20, fontWeight: "bold" }}
        >
          {state.flavors.map((tab, ind) => (
            <Tab key={tab.id + ind} eventKey={tab.name} title={tab.name || ""} className="tab_container">
              <TabDataKitting state={tab} handleChange={handleChange} />
            </Tab>
          ))}
        </Tabs>
      </Row>
    </Row>
  );
}
