import React from "react";

export default function PopupStationNotActive({ isManagerPage, text }) {
  return (
    <div className={`popup_container${isManagerPage ? " man_page_pop" : ""}`}>
      <div className="block">
        <h2 className="text-center">{text ? text : "This Shift isn't Active"}</h2>
        {!isManagerPage && <h4 className="text-center">Please contact with Manager</h4>}
      </div>
    </div>
  );
}
