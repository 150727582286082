import { generateUniqID, randomHexColor, randomValue } from "./utils/utils";

export const BASE_API = "https://dataportalserver.winechips.com/v1";
export const BASE_API_DEV = "https://staging-dataportalserver.winechips.com/v1";

export const COLORS = {
  NumberPad: "#10696A",
  LabelData: "#E87B00",
  DinamicLabel: "#EE476F",
  Date_timePicker: "#1189B1",
  Dropdown: "#BAB9A0",
  Calculation: "#A3D4D6",
  toggleTurns_button: "#E3BF86",

  fryer1: "#6b180a",
  fryer2: "#ab331f",
  fryer3: "#f2654e",
  fryer4: "#db892c",
  fryer5: "#854e10",
  fryer6: "#b2b51b",
};

export const MANAGER_CODE = "731";

export const PINS = [
  { name: "Izaiah Pribyl", code: "56369", value: 14 },
  { name: "Nicholette Pribyl", code: "68661", value: 4 },
  { name: "Shinta Tan", code: "69532", value: 6 },
  { name: "Frans Tanade", code: "63477", value: 7 },
  { name: "Zackery Nezamis", code: "54361", value: 2 },
  { name: "Victor Marcus", code: "34252", value: 5 },
  { name: "Jonathan Strietzel", code: "731", value: 8 },
  { name: "Shanon Kidd", code: "63204", value: 1 },
  { name: "Admin", code: "6541", value: 0 },
];

export const EXAMPLE_PRODUCTS = [
  "Smoked Gauda",
  "Asiago",
  "Blue Cheese",
  "Manchego",
  "Dry aged Ribeye",
  "Billionaire's Bacon",
  "Spicy Calabrese",
  "Sel Gris",
  "Hawaiian Red Sea Salt",
  "Black Lava Jalapeno",
  "Custom Flavor",
];

export const bag_sizes = ["3 OZ.", "1 OZ."];

export const STATIONS = ["raw", "fryer", "qa", "seasoning", "kitting"];

export const CODE_FLAVOR = [
  { color: "#FE5D07", code: "01", contractions: "SG", name: "Smoked Gouda" },
  { color: "#733C68", code: "11", contractions: "AG", name: "Asiago Cheese" },
  { color: "#EB2C41", code: "03", contractions: "MC", name: "Manchego" },
  { color: "#0486E5", code: "02", contractions: "BC", name: "Blue Cheese" },
  { color: "#DA453F", code: "13", contractions: "BB", name: "Billionaire's Bacon" },
  { color: "#957854", code: "04", contractions: "DAR", name: "Dry Aged Ribeye" },
  { color: "#E73500", code: "08", contractions: "SC", name: "Spicy Calabrese" },
  { color: "#767467", code: "16", contractions: "SLG", name: "Sel Gris" },
  { color: "#E7740B", code: "06", contractions: "HRSS", name: "Hawaiian Red Sea Salt" },
  { color: "#636557", code: "15", contractions: "BLJ", name: "Black Lava Jalapeno" },

  { color: "#000000", code: "00", contractions: "CF_custom", name: "Custom Flavor" },
  { color: "#000000", code: "05", contractions: "BS", name: "Bombay Spice" },
  { color: "#000000", code: "07", contractions: "SCC", name: "Sweet Coconut Curry" },
  { color: "#000000", code: "09", contractions: "TLG", name: "Thai Lemongrass" },
  { color: "#000000", code: "10", contractions: "MR", name: "Master Reserve" },
  { color: "#000000", code: "12", contractions: "JI", name: "Jamon Iberico" },
  { color: "#000000", code: "14", contractions: "SCD", name: "Serrano Cheddar" },
  { color: "#000000", code: "17", contractions: "HPR", name: "Horseradish Prime Rib" },
  { color: "#000000", code: "18", contractions: "CB", name: "Cayenne Bleu" },
  { color: "#000000", code: "19", contractions: "BP", name: "Baked Potato" },
  { color: "#000000", code: "20", contractions: "RTB", name: "Rays Texas BBQ" },
  { color: "#000000", code: "21", contractions: "S&V", name: "Salt & Vinegar" },
  { color: "#000000", code: "22", contractions: "TCR", name: "The Chocolate Room" },
  { color: "#000000", code: "23", contractions: "HSV", name: "Himalayan Salt & Vinegar" },
  { color: "#000000", code: "24", contractions: "SOG", name: "Smoked Onion Gouda" },
  { color: "#000000", code: "25", contractions: "CH", name: "Crispy Ham / Holiday Ham" },
  { color: "#000000", code: "26", contractions: "SP", name: "Sweet Potato" },
  { color: "#000000", code: "27", contractions: "SSG", name: "Spicy Smoked Gouda" },
  { color: "#000000", code: "28", contractions: "S&P", name: "Salt & Pepper" },
  { color: "#000000", code: "29", contractions: "RAB", name: "Red Arrow BBQ" },
];

export const CODE_FLAVORS_ESTATE = [
  { color: "#FE5D07", code: "01", contractions: "SG", name: "Smoked Gouda" },
  { color: "#733C68", code: "11", contractions: "AG", name: "Asiago Cheese" },
  { color: "#EB2C41", code: "03", contractions: "MC", name: "Manchego" },
  { color: "#0486E5", code: "02", contractions: "BC", name: "Bleu Cheese" },
  { color: "#DA453F", code: "13", contractions: "BB", name: "Billionaire Bacon" },
  { color: "#957854", code: "04", contractions: "DAR", name: "Dry Aged Ribeye" },
  { color: "#E73500", code: "08", contractions: "SC", name: "Spicy Calabrese" },
  { color: "#767467", code: "16", contractions: "SLG", name: "Sel Gris" },
  { color: "#E7740B", code: "06", contractions: "HRSS", name: "Hawaiian Red Sea Salt" },
  { color: "#636557", code: "15", contractions: "BLJ", name: "Black Lava JalapeÑo" },
];

export const yes_no_options = [
  { name: "Yes", value: true },
  { name: "No", value: false },
];

export const clean_ups_stations = {
  raw: ["buckets", "peeler", "urschel", "floor", "tools"],
  qa: [
    "move_filled_bucket_to_seasoning",
    "clean_scale_and_tote",
    "clean_table",
    "clean_chip_containers",
    "take_out_waste_containers",
    "wash_dishes",
    "clean_floor",
  ],
  fry: ["wipe_down_fryers", "clean_floors", "clean_fryer_tools"],
  seasoning: ["clean_cambrios", "clean_buckets", "dishes", "dan", "table_sealer", "floor"],
};

export function propToName(prop) {
  return prop.split("_").join(" ");
}
export const HEAD_TABLE_EMPLOYEES = ["Employee", "In", "Out", "Status", "Min", "", "Notes"];
export const HEAD_TABLE_CLEAN_UP = ["", "Employee"];
export const HEAD_TABLE_ENTER_NOTES = ["Employee", "Note", ""];
export const HEAD_TABLE_OIL_USED = ["#", "Weight", ""];
export const ADDED_PROP_EMPLOYEE = [
  { name: "None", value: 11111112 },
  { name: "Training", value: 11111111 },
];

export const tamplateFlavorSeasoning = {
  bag_size: "",
  flavor: "",
  customFlavor: "",
  batches: "",
  allergen: true,
  flavor_start_time: "",
  batches_completed: 0,
  wheel_code: "",
  code_made_by: { name: "", value: "" },
  code_verified_by: { name: "", value: "" },
  seasoner: [],
  bagger: [],
  sealer: [],
  bags_mode: "",
  lot_number: "",
  flavor_end_time: "",
};
export const tamplateFlavorKitting = {
  bag_size: "",
  flavor: "",
  customFlavor: "",
  estimatedNumberOfBags: 0,
  singles: 0,
  refills: 0,
  extraBags: "Refills",
  lessBags: "Singles",

  flavor_start_time: "",
  singles_completed: 0,
  refills_completed: 0,
  were_all_bags_kitted: "NO",
  number_of_bags_left_to_kit: "",
  flavor_end_time: "",
};

export const COLUMNS_DASHBOARD_FLAVORS = [
  "ESTATE FLAVOR",
  "3oz Refills",
  "1oz Refills",
  "3oz Tubes",
  "1oz Tubes",
  "3oz Empty Tubes",
  "1oz Empty Tubes",
  "3oz Master Cases",
  "1oz Master Cases",
];

export const MANAGER_INIT = {
  managerInformation: {
    expectedNumerOfEmployees: "",
    poundsOfPotatoesOnHand: "",
    batchesToBeSeasoned: "",
    activeStations: { raw: false, fry: false, qa: false, seasoning: false, kitting: false },
    productionEmployee: { assignedEmployees: [] },
  },
  raw: {
    bucket_goal: "",
    assignedEmployee: { name: "", value: "" },
    assignedBreakeEmployee: { name: "", value: "" },
    assignedBreakeTime: "", //time After All Potatoes To Fryer
    firstPotatoesToFry: "", //time
    lastPotatoesToFry: "", //time
    containerType: "",
    numberOfContainers: "",
    container_weight_with_potatoes: "",
    container_weight_with_potatoes_verified: false,
    empty_container_weight: "",
    empty_container_weight_verified: false,
    number_of_containers_completed: "",
    activeEmployee: [],
    firstPotatoesToFryEmployee: "", //time
    lastPotatoesToFryEmployee: "", //time
    clean_ups: {
      buckets: { name: "", value: "" },
      peeler: { name: "", value: "" },
      urschel: { name: "", value: "" },
      floor: { name: "", value: "" },
      tools: { name: "", value: "" },
    },
    enter_notes: [],
    manager_notes: "",
    isStationActive: false,
  },
  fry: {
    assignedEmployeeFryer: { name: "", value: "" },
    assignedEmployeeFloat: { name: "", value: "" },
    assignedBreakeEmployeeFryer: { name: "", value: "" },
    assignedBreakeEmployeeFloat: { name: "", value: "" },
    assignedBreakeEmployeeFryerTime: "",
    assignedBreakeEmployeeFloatTime: "",
    firstBucketInFryer: "", //time
    lastBucketOutFryer: "", //time
    bucket_weight_with_chips: "",
    bucket_weight_with_chips_verified: false,
    empty_bucket_weight: "",
    empty_bucket_weight_verified: false,
    bucket_weight_oil: "",
    bucket_weight_oil_verified: false,
    fryers: [
      { fryer: "1", temp: "", filtered: false, filteredBy: { name: "", value: "" }, active: false },
      { fryer: "2", temp: "", filtered: false, filteredBy: { name: "", value: "" }, active: false },
      { fryer: "3", temp: "", filtered: false, filteredBy: { name: "", value: "" }, active: false },
      { fryer: "4", temp: "", filtered: false, filteredBy: { name: "", value: "" }, active: false },
      { fryer: "5", temp: "", filtered: false, filteredBy: { name: "", value: "" }, active: false },
      { fryer: "6", temp: "", filtered: false, filteredBy: { name: "", value: "" }, active: false },
      { fryer: "7", temp: "", filtered: false, filteredBy: { name: "", value: "" }, active: false },
      { fryer: "8", temp: "", filtered: false, filteredBy: { name: "", value: "" }, active: false },
    ],

    firstBucketInFryerEmployee: "",
    lastBucketOutFryerEmployee: "",
    buckets_in_fryer: 0,
    partial_bucket_weight: "",
    activeEmployee: [],
    clean_ups: {
      wipe_down_fryers: { name: "", value: "" },
      clean_floors: { name: "", value: "" },
      clean_fryer_tools: { name: "", value: "" },
    },
    enter_notes: [],
    oil_used: [],
    fryer_notes: [],
    manager_notes: "",
    isStationActive: false,
  },
  qa: {
    startingBucketWeight: "",
    assignedEmployeesStart: { name: "", value: "" },
    assignedEmployeesStartTime: "",
    assignedEmployeesAfterFloat: { name: "", value: "" },
    assignedEmployeesAfterFloaTime: "",
    assignedEmployeesAfterRawCleanUp: { name: "", value: "" },
    assignedEmployeesAfterRawCleanUpTime: "",
    assignedEmployeesAfterFryerCleanUp: { name: "", value: "" },
    assignedEmployeesAfterFryerCleanUpTime: "",
    bucket_weight_with_chips: "",
    bucket_weight_with_chips_verified: false,
    empty_bucket_weight: "",
    empty_bucket_weight_verified: false,
    container_weight: "",
    container_weight_verified: false,
    buckets_filled: 0,
    partial_bucket_weight: "",
    activeEmployee: [],
    pre_qa_weight: [],
    clean_ups: {
      move_filled_bucket_to_seasoning: { name: "", value: "" },
      clean_scale_and_tote: { name: "", value: "" },
      clean_table: { name: "", value: "" },
      clean_chip_containers: { name: "", value: "" },
      take_out_waste_containers: { name: "", value: "" },
      wash_dishes: { name: "", value: "" },
      clean_floor: { name: "", value: "" },
    },
    enter_notes: [],
    manager_notes: "",
    isStationActive: false,
  },
  seasoning: {
    assignedEmployees: [],
    flavors: [{ name: "Flavor: 1", id: generateUniqID(), ...tamplateFlavorSeasoning }],
    activeEmployee: [],
    clean_ups: {
      clean_cambrios: { name: "", value: "" },
      clean_buckets: { name: "", value: "" },
      dishes: { name: "", value: "" },
      dan: { name: "", value: "" },
      table_sealer: { name: "", value: "" },
      floor: { name: "", value: "" },
    },
    enter_notes: [],
    manager_notes: "",
    isStationActive: false,
  },
  kitting: {
    assignedEmployees: [],
    flavors: [{ name: "Flavor: 1", ...tamplateFlavorKitting }],
    activeEmployee: [],
    enter_notes: [],
    loss: {
      bags_with_bad_seals: 0,
      bad_tubes: 0,
      dented_lids: 0,
      missing_tubes: 0,
    },
    manager_notes: "",
    isStationActive: false,
  },
};

export function TESTdata(onlyEstate) {
  let listFlvUse = onlyEstate
    ? CODE_FLAVORS_ESTATE
    : CODE_FLAVOR.filter(({ code }) => !CODE_FLAVORS_ESTATE.find((fg) => fg.code === code));

  console.log("listFlvUse", listFlvUse);

  const data = listFlvUse.map((ir) => ({
    ...ir,
    "3oz Refills": randomValue(),
    "1oz Refills": randomValue(),
    "3oz Tubes": randomValue(),
    "1oz Tubes": randomValue(),
    "3oz Empty Tubes": randomValue(),
    "1oz Empty Tubs": randomValue(),
    "3oz Master Cases": randomValue(),
    "1oz Master Cases": randomValue(),
  }));

  return data;
}
