import React from "react";
import { Col, Form, InputGroup, Button, Row } from "react-bootstrap";

import { COLORS, bag_sizes, CODE_FLAVOR, yes_no_options } from "../../../config";

export default function TabData({ isOnlyOneFlavor, handleChange, state }) {
  return (
    <>
      <Row className="mb-3">
        <Col xs={6}>
          <Form.Select
            onChange={handleChange}
            data-handle1="flavors"
            data-handle3="flavor"
            data-handle4={state.id}
            className="fs_c_20"
            style={{ color: COLORS.LabelData }}
            value={state.flavor}
          >
            <option style={{ fontSize: 12, color: "gray" }}>Choose flavor</option>
            {CODE_FLAVOR.map((em) => (
              <option key={em.contractions} value={em.code}>
                {em.name}
              </option>
            ))}
          </Form.Select>
        </Col>

        {state.flavor === "00" && (
          <Col>
            <InputGroup className="">
              <InputGroup.Text className="fs_c_20">Custom Flavor:</InputGroup.Text>
              <Form.Control
                className="fs_c_20 text-end"
                type="text"
                style={{ color: COLORS.LabelData }}
                onChange={handleChange}
                data-handle1="flavors"
                data-handle3="customFlavor"
                data-handle4={state.id}
                value={state.customFlavor}
              />
            </InputGroup>
          </Col>
        )}
      </Row>
      <Row className="mb-3">
        <Col>
          <InputGroup className="mb-2">
            <InputGroup.Text className="fs_c_20">Batches:</InputGroup.Text>
            <Form.Control
              className="fs_c_20 text-end"
              type="number"
              style={{ color: COLORS.LabelData }}
              onChange={handleChange}
              data-handle1="flavors"
              data-handle3="batches"
              data-handle4={state.id}
              value={state.batches}
            />
          </InputGroup>
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text className="fs_c_20">Bag Size:</InputGroup.Text>

            <Form.Select
              onChange={handleChange}
              data-handle1="flavors"
              data-handle3="bag_size"
              data-handle4={state.id}
              className="fs_c_20"
              style={{ color: COLORS.LabelData }}
              value={state.bag_size}
            >
              <option style={{ fontSize: 12, color: "gray" }}>Choose bag size</option>
              {bag_sizes.map((em) => (
                <option key={em} value={em}>
                  {em}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text className="fs_c_20">Allergen:</InputGroup.Text>
            <Form.Select
              onChange={handleChange}
              data-handle1="flavors"
              data-handle3="allergen"
              data-handle4={state.id}
              className="fs_c_20"
              style={{ color: COLORS.LabelData }}
              value={state.allergen}
            >
              <option style={{ fontSize: 12, color: "gray" }}>Choose allergen</option>
              {yes_no_options.map((em) => (
                <option key={em.name} value={em.value}>
                  {em.name}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={!isOnlyOneFlavor ? 5 : 8}></Col>
        <Col xs={4}>
          <Button
            size="lg"
            className="mt-1 p-2"
            style={{ fontSize: 20 }}
            data-handle1="flavors"
            data-handle3="add_new_flavor"
            onClick={handleChange}
          >
            Add New Flavor
          </Button>
        </Col>
        {!isOnlyOneFlavor && (
          <Col xs={3}>
            <Button
              variant={"danger"}
              size="lg"
              className="mt-1"
              style={{ padding: "10px", fontSize: 20 }}
              data-handle1="flavors"
              data-handle3="delete_flavor"
              data-handle4={state.id}
              onClick={handleChange}
            >
              Delete Flavor
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
}
