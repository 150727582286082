import React, { useEffect, useState } from "react";

import { Alert, Button, Col, Form, Row, Navbar, Container, CardBody, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBurger, faLock } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";

import useToken from "../App/useToken";
import useUser from "../App/useUser";

import { loginUser } from "../../utils/api";

export default function ForgotPassword() {
  const { setToken } = useToken();
  const { setUser } = useUser();

  const [state, setState] = useState({
    email: "",
    password: "",
    confirm_password: "",
    hasBeenVerified: false,
    error: null,
  });

  useEffect(() => {
    const codeFromURL = window.location.hash.slice(18);
    console.log("codeFromURL", codeFromURL);
    if (codeFromURL) {
      setState({ ...state, hasBeenVerified: true });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!state.hasBeenVerified) {
      setState({ ...state, hasBeenVerified: true });
    } else {
      setState({ ...state, hasBeenVerified: false });
    }
  };

  return (
    <div className="pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          {state.error && (
            <Alert variant="danger" className="row">
              {`${
                state.error.error &&
                (state.error.error.msg || "") + " " + (state.error.error.code ? "(" + state.error.error.code + ")" : "")
              }`}
            </Alert>
          )}

          <Col lg={12} className="mb-6 mt-3">
            <div className="text-center mb-1 text-muted">
              <Link to="/" className="d-block auth-logo">
                <img alt="logo-wide" src="/logo-wide.png" width="70%" id="logoImg" className="mx-auto py-2 my-2" />
              </Link>
            </div>
          </Col>

          <Col md={8} lg={6} xl={5} style={{ boxShadow: "0px 0px 12px -4px rgba(0,0,0,0.75)", padding: "40px 40px" }}>
            <h2></h2>

            <Form onSubmit={handleSubmit}>
              {state.hasBeenVerified ? (
                <>
                  <Form.Group className="mb-3 row">
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="New Password *"
                      onChange={(e) => setState({ ...state, password: e.target.value })}
                      autoComplete="off"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 row">
                    <Form.Control
                      type="password"
                      placeholder="Confirm New Password *"
                      name="confirm_password"
                      onChange={(e) => setState({ ...state, confirm_password: e.target.value })}
                      autoComplete="off"
                    />
                  </Form.Group>
                  {/* <Form.Group className="mb-3 pb-3 row">
              <Form.Label>Choose role</Form.Label>
              <Form.Select
                // onChange={handleChange}
                data-handle="active_employee"
                aria-label="Default select example"
                // value={state.active_employee}
                className="fs_c_30"
                // style={{ color: COLORS.Dropdown }}
              >
                {permissions.map((em) => (
                  <option key={em.value} value={em.value}>
                    {em.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group> */}
                </>
              ) : (
                <Form.Group className="mb-3 row">
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Email *"
                    onChange={(e) => setState({ ...state, email: e.target.value })}
                    autoComplete="off"
                  />
                </Form.Group>
              )}

              <div className="mt-1 text-center">
                <Link to="/" className="text-muted">
                  <small style={{ marginLeft: 10 }}>Remembered credentials?</small>
                </Link>
              </div>
              <Form.Group className="mb-3 pt-3 row">
                <Button type="submit" style={{ width: "50%", margin: "0 auto" }}>
                  Send
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
