import { BASE_API, BASE_API_DEV } from "../config";
import { getCurentDate } from "./utils";

const API = BASE_API; // BASE_API_DEV for development

const ROUTES_WITHOU_TOKEN = ["/users/login"];

async function call(method = "GET", url, config = {}, asFile) {
  return new Promise((res, rej) => {
    try {
      if (!ROUTES_WITHOU_TOKEN.includes(url) && !localStorage.getItem("token")) throw new Error("token is EMPTY");

      const authUser = JSON.parse(localStorage.getItem("token"));
      let token = null;
      if (authUser) token = authUser.token;
      if (!ROUTES_WITHOU_TOKEN.includes(url) && !token) throw new Error("YOU ARE NOT LOGIN");

      const options = {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (method === "POST" || method === "PUT") {
        options.body = JSON.stringify(config);
      }

      if (config instanceof FormData) {
        delete options.headers["Content-Type"];
        if (method === "POST" || method === "PUT") {
          options.body = config;
        }
      }  

      fetch(API + url, options)
        .then((resp) => {
          console.log(resp.status, method, url, config);
          if (resp.status === 401 || resp.status === 403) {
            localStorage.setItem("token", null);
            localStorage.setItem("user", null);
            return (window.location.href = "/login");
          }

          if (asFile) {
            return resp.blob();
          }

          return resp.json();
        })
        .then((r) => {
          if (asFile) {
            const blobUrl = URL.createObjectURL(r);
            const a = document.createElement("a");
            a.href = blobUrl;
            a.download = `inventories_${Date.now()}.csv`;
            a.click();
            // Clean up: revoke the Blob URL
            URL.revokeObjectURL(blobUrl);
            return;
          }

          if (r.error) {
            rej(r.error);
            return;
          }
          res(r);
        })
        .catch((e) => {
          console.log("ERROR IN FETCH CALL", e);
          rej(e);
        });
    } catch (error) {
      console.log("ERROR IN CALL", error);
      rej(error);
    }
  });
}

export const loginUser = (credentials) => call("POST", "/users/login", credentials);
export const getItems = () => call("GET", "/inventory");
export const getInventoryReceiving = () => call("GET", "/inventory/receiving");
export const getInventoryBuilds = () => call("GET", "/inventory/builds");

export const importInventory = () => call("GET", "/import");
export const updateItem = (itemId, data) => call("PUT", `/inventory/${itemId}`, data);
export const getItemHistory = (itemId) => call("GET", `/inventory/${itemId}/history`);
export const getEmployees = () => call("GET", `/employees`);
export const updateEmployeeStatus = (employeeId) => call("PUT", `/employees/${employeeId}/timer`);

export const getStateStation = (station, date, shift) =>
  call("GET", `/states/${station}${date ? `?day=${date}&shift=${shift}` : ""}`); //station: seasoning/ qa/ fryer/ raw/ potatostorage/ finishedgoods / kitting
export const createStationShift = (station, day, state) => call("POST", `/states/${station}/shifts?day=${day}`, state);
export const deleteStationShift = (station, shiftId) => call("DELETE", `/states/${station}/shifts/${shiftId}`);

export const updateStateStation = (station, cr, date, shift) =>
  call("PUT", `/states/${station}${date ? `?day=${date}&shift=${shift}` : ""}`, cr);

export const openStationShift = (station, cr) => call("PUT", `/states/${station}/open`, cr);
export const closeStationShift = (station, cr) => call("PUT", `/states/${station}/close`, cr);

export const getStationShiftsListPerDay = (station, date = getCurentDate()) =>
  call("GET", `/states/${station}/shifts?day=${date}`);

export const getStateStationDashboard = (station, from, to) => {
  if (from && to) {
    return call("GET", `/states/${station}/dashboard?from=${from}&to=${to}`);
  }
  return call("GET", `/states/${station}/dashboard`);
};

export const getSettings = () => call("GET", `/settings`);
export const updateSettings = (cr) => call("PUT", `/settings`, cr);

export const importInventories = (file) => call("POST", `/inventory/import/file`, file);
export const exportInventories = () => call("GET", `/inventory/export/file`, null, "asFile");

export const getLogs = () => call("GET", `/log`);

export const getDashboardFlavorsPack = () => call("GET", `/dashboard/flavor-pack`);
export const updateDashboardFlavorsPack = (cr) => call("PUT", `/dashboard/flavor-pack`, cr);

export const getInventorySKU = () => call("GET", `/inventory/sku`);

export const getDashboardOrders = (page = 1, limit = 25, offset = 26) =>
  call("GET", `/dashboard/orders?limit=${limit}&page=${page}&offset=${offset}`);
