import React from "react";
import { Col, Row, InputGroup, Form } from "react-bootstrap";

import { exportInventories, importInventories } from "../../../utils/api";

export default function ImportExport() {
  const handleExport = () => {
    exportInventories()
      .then((r) => {
        console.log(r);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleImport = (e) => {
    const file = e.target.files[0];
    console.log("IMPORT", file);
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("files", file, file?.name);

    importInventories(formData)
      .then((r) => {
        console.log(r);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Row className="">
      <Col>
        <InputGroup className="mb-2 cse">
          <Form.Group controlId="import" className="import_group">
            <Form.Control type="file" onChange={handleImport} accept="csv" />
            <Form.Label>Import</Form.Label>
          </Form.Group>
          <Form.Label className="descr">
            Import inventories from local machine. It must be file in .csv format.
          </Form.Label>
        </InputGroup>

        <InputGroup className="mb-2 cse">
          <Form.Group controlId="export" className="import_group">
            <Form.Control type="button" />
            <Form.Label onClick={handleExport}>Export</Form.Label>
          </Form.Group>
          <Form.Label>Export inventories to local machine.</Form.Label>
        </InputGroup>
      </Col>
    </Row>
  );
}
