import React, { useEffect, useState, useRef } from "react";
import { Row, InputGroup, Button, Form } from "react-bootstrap";

import { COLORS, MANAGER_INIT } from "../../../config";

import PopupActiveEmployee from "../../../helpers_components/PopupsStations/ActiveEmployee";
import PopupCleanUp from "../../../helpers_components/PopupsStations/CleanUp";
import PopupEnterNotes from "../../../helpers_components/PopupsStations/EnterNotes";
import PopupPreQA from "../../../helpers_components/PopupsStations/PreQA_weight";

import CommonFunctionality, { CalcParams } from "../../../utils/utils";
import LoaderFull from "../../../helpers_components/Loader";
import { ShiftManage } from "../../../helpers_components/ShiftManage";

export default function QADaily({ date, employees, set_number_of_batches_mode }) {
  const snapShotManagerState = useRef(MANAGER_INIT.qa);

  const [state, setState] = useState(MANAGER_INIT.qa);
  const [shiftState, setShiftState] = useState({ activeShift: 0, list: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    CommonFunctionality.getDataStationManager(
      "qa",
      snapShotManagerState,
      date,
      shiftState.activeShift,
      setLoading,
      setShiftState,
      setState
    );
  }, [date, shiftState.activeShift]);

  useEffect(() => {
    CommonFunctionality.updateStatePerChange(
      "qa",
      snapShotManagerState,
      state,
      setLoading,
      date,
      shiftState.activeShift
    );
  }, [state]);

  const handleChange = ({ target }) => {
    const attr1 = target.getAttribute("data-handle1");
    let value = target.value;

    if (attr1.includes("verified")) {
      setState((pS) => ({
        ...pS,
        [attr1]: !pS[attr1],
      }));
      return;
    }

    setState((pS) => ({
      ...pS,
      [attr1]: value,
    }));
  };

  const number_of_batches_mode = CalcParams(state, "number_of_batches_mode");

  set_number_of_batches_mode(number_of_batches_mode);

  const handleSaveDataFromPopups = (type, data) => {
    console.log(type, data);
    setState((pS) => ({ ...pS, [type]: data }));
  };

  return (
    <>
      <div className="fs_c_40 text-center">
        QA Station <span className="success_color">{state.isStationActive ? "Active" : "Not active"}</span>
      </div>

      <LoaderFull show={loading} />

      <ShiftManage
        createShift={null}
        shiftState={shiftState}
        setShiftState={setShiftState}
        handleChange={handleChange}
        isStationActive={state.isStationActive}
        deleteShift={() => {}}
      />
      {shiftState.list.length > 0 && (
        <>
          <Row className="">
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Pre QA Pounds:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={CalcParams(state, "pre_qa_pounds")}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Post QA Pounds:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={CalcParams(state, "post_qa_pounds")}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Number of Batches Made:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={number_of_batches_mode}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Total Minutes:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={CalcParams(state, "totalMinutes")}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Number of Employees:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={CalcParams(state, "number_of_employees")}
              />
            </InputGroup>
          </Row>
          <h3 className="fs_c_30 text-center">Process</h3>
          <Row className="">
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Buckets Filled:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                data-handle1="buckets_filled"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.buckets_filled || ""}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Partial (Final ) Bucket Weight:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="text"
                data-handle1="partial_bucket_weight"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.partial_bucket_weight || ""}
                onChange={handleChange}
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Bucket Weight with Chips:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                data-handle1="bucket_weight_with_chips"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.bucket_weight_with_chips || ""}
                onChange={handleChange}
              />
              <Button
                onClick={handleChange}
                className="button_verify"
                data-handle="bucket_weight_with_chips_verified"
                variant={!state.bucket_weight_with_chips_verified ? "danger" : "light"}
              >
                {!state.bucket_weight_with_chips_verified ? "Verify" : "Verified"}
              </Button>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Empty Bucket Weight:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                data-handle1="empty_bucket_weight"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.empty_bucket_weight || ""}
                onChange={handleChange}
              />
              <Button
                onClick={handleChange}
                className="button_verify"
                data-handle="empty_bucket_weight_verified"
                variant={!state.empty_bucket_weight_verified ? "danger" : "light"}
              >
                {!state.empty_bucket_weight_verified ? "Verify" : "Verified"}
              </Button>
            </InputGroup>
          </Row>

          <PopupActiveEmployee
            asBlock={true}
            employeesFullList={employees}
            employeesListFromDB={state?.activeEmployee}
            handleSaveDataFromPopups={handleSaveDataFromPopups}
          />

          <PopupPreQA
            asBlock={true}
            container_weight={state?.container_weight}
            container_weight_verified={state?.container_weight_verified}
            pre_qa_weight={state?.pre_qa_weight}
            handleSaveDataFromPopups={handleSaveDataFromPopups}
            handleChangeCustom={handleChange}
          />

          <PopupCleanUp
            asBlock={true}
            employeesFullList={employees}
            clean_ups={state?.clean_ups}
            handleSaveDataFromPopups={handleSaveDataFromPopups}
            station={"qa"}
          />

          <PopupEnterNotes
            asBlock={true}
            employeesFullList={employees}
            enter_notes={state?.enter_notes}
            handleSaveDataFromPopups={handleSaveDataFromPopups}
          />

          <Row>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Manager Notes for QA:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="text"
                data-handle1="manager_notes"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.manager_notes || ""}
                onChange={handleChange}
              />
            </InputGroup>
          </Row>
        </>
      )}
    </>
  );
}
