import React, { useRef, useState } from "react";
import { Row, InputGroup, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { filterEmpl } from "../../../utils/utils";
import { COLORS, ADDED_PROP_EMPLOYEE } from "../../../config";

export default function ProductionEmployee({ managerState, handleAssignedEmployees, employees }) {
  const dragItem = useRef();
  const dragOverItem = useRef();

  const [active, setActive] = useState(null);

  const dragStart = (e) => {
    dragItem.current = e.target.id;
    setActive(e.target.id);
  };
  const dragEnter = (e) => {
    dragOverItem.current = e.currentTarget.id;
  };
  const drop = (e) => {
    const copyLists = [...managerState.productionEmployee.assignedEmployees];
    const dragItemContent = copyLists[dragItem.current];
    copyLists.splice(dragItem.current, 1);
    copyLists.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setActive(null);
    handleAssignedEmployees(e, copyLists);
  };

  return (
    <Row className="">
      {managerState.productionEmployee.assignedEmployees.length > 0 && (
        <ul style={{ width: "100%", maxHeight: 425, overflow: "auto" }}>
          {managerState.productionEmployee.assignedEmployees.map((se, ind) => (
            <li
              key={se.value}
              id={ind}
              className="d-flex align-items-center justify-content-between mb-2"
              style={{
                width: "100%",
                borderBottom: "1px solid #000",
                color: active ? (active === ind ? "#ff0000" : "#999999") : "#000",
              }}
              draggable
              onDragStart={dragStart}
              onDragEnter={dragEnter}
              onDragEnd={drop}
              data-handle1="productionEmployee"
              data-handle2="assignedEmployees"
              data-handle3="change_order"
            >
              <div>
                <FontAwesomeIcon icon={faGripVertical} style={{ width: 30, height: 30, color: "#999999" }} />
                <strong style={{ fontSize: 35, width: "70%", marginLeft: 20 }}>{se?.name}</strong>
              </div>

              <Button
                style={{ marginLeft: 30, width: 50 }}
                variant={"outline-danger"}
                data-handle1="productionEmployee"
                data-handle2="assignedEmployees"
                data-handle3="delete_empl"
                data-handle4={se?.value}
                onClick={handleAssignedEmployees}
                size="sm"
              >
                <FontAwesomeIcon
                  size="lg"
                  data-handle1="productionEmployee"
                  data-handle2="assignedEmployees"
                  data-handle3="delete_empl"
                  data-handle4={se?.value}
                  icon={faTrash}
                />
              </Button>
            </li>
          ))}
        </ul>
      )}

      <Form.Select
        onChange={handleAssignedEmployees}
        data-handle1="productionEmployee"
        data-handle2="assignedEmployees"
        data-handle3="select_new"
        className="fs_c_30"
        style={{ color: COLORS.Dropdown, fontSize: 30 }}
      >
        <option style={{ fontSize: 20, color: "gray" }}>Select Employee</option>
        {ADDED_PROP_EMPLOYEE.map((em) => (
          <option key={em?.value} value={em?.value}>
            {em?.name}
          </option>
        ))}
        {filterEmpl(employees, managerState.productionEmployee.assignedEmployees).map((em) => (
          <option key={em?.value} value={em?.value}>
            {em?.name}
          </option>
        ))}
      </Form.Select>
    </Row>
  );
}
