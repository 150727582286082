import React, { useState, useEffect } from "react";
import { Table, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

import { COLORS, HEAD_TABLE_ENTER_NOTES } from "../../config";

export default function PopupEnterNotes({
  asBlock,
  enter_notes,
  employeesFullList,
  closeModal,
  handleSaveDataFromPopups,
}) {
  const [enter_notes_list, set_enter_notes_list] = useState(enter_notes || []);

  useEffect(() => {
    set_enter_notes_list(enter_notes);
  }, [enter_notes]);

  const handleChange = ({ target }) => {
    let attr1 = target.getAttribute("data-handle1");
    let attr2 = target.getAttribute("data-handle2");
    if (!attr1) {
      attr1 = target.parentNode.getAttribute("data-handle1");
    }
    if (!attr2) {
      attr2 = target.parentNode.getAttribute("data-handle2");
    }

    let value = target.value;

    console.log(attr1, attr2, value);

    if (attr1 === "add_new") {
      const getEmpl = employeesFullList.find((em) => +em.value === +value);
      set_enter_notes_list((pS) => [
        ...pS,
        { ...getEmpl, note: "", id: `${getEmpl.value}_${enter_notes_list.length}` },
      ]);
    }
    if (attr1 === "change_note") {
      set_enter_notes_list((pS) =>
        pS.map((et) => {
          if (et.id === attr2) {
            return { ...et, note: value };
          }
          return et;
        })
      );
    }

    if (attr1 === "delete") {
      set_enter_notes_list((pS) => pS.filter((emp) => emp.id !== attr2));
    }
  };

  const saveChanges = () => {
    handleSaveDataFromPopups("enter_notes", enter_notes_list);
  };

  return (
    <div className={asBlock ? "" : "popup_container"}>
      <div className={`block ${asBlock ? "daily" : ""}`}>
        {!asBlock && (
          <div className="close_button" onClick={closeModal}>
            <FontAwesomeIcon icon={faCircleXmark} size="2xl" style={{ color: "#511f2e", padding: 10 }} />
          </div>
        )}
        <div className="fs_c_30 text-center">Enter Notes</div>
        <Table bordered hover>
          <thead>
            <tr>
              {HEAD_TABLE_ENTER_NOTES.map((th) => (
                <th style={{ textAlign: "center", fontSize: 25 }} key={th}>
                  {th}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {enter_notes_list &&
              enter_notes_list.map((st, ind) => (
                <tr key={st.id}>
                  <td className="clean_ups_title">{st.name}</td>
                  <td>
                    <Form.Control
                      className="fs_c_20"
                      data-handle1="change_note"
                      data-handle2={st.id}
                      type="text"
                      style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
                      value={st.note}
                      onChange={handleChange}
                    />
                  </td>
                  <td
                    data-handle1="delete"
                    data-handle2={st.id}
                    onClick={handleChange}
                    style={{ textAlign: "center", fontSize: 18 }}
                  >
                    <FontAwesomeIcon
                      data-handle1="delete"
                      data-handle2={st.id}
                      icon={faTrash}
                      size="xl"
                      style={{ color: "#ff0000", padding: 10 }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <Form.Select
          onChange={handleChange}
          data-handle1="add_new"
          className="fs_c_20"
          style={{ color: COLORS.Dropdown }}
        >
          <option style={{ fontSize: 12, color: "gray" }}>Select Employee</option>
          {employeesFullList &&
            employeesFullList.map((em) => (
              <option key={em.value} value={em.value}>
                {em.name}
              </option>
            ))}
        </Form.Select>
        {/* {!asBlock && ( */}
        <Button
          size="lg"
          className={asBlock ? "" : `save_button mt-3`}
          style={{ padding: "10px 20px" }}
          onClick={saveChanges}
        >
          Save Enter Notes
        </Button>
        {/* )} */}
      </div>
    </div>
  );
}
