import React, { useEffect, useState } from "react";
// import { Link, useHistory, useLocation } from "react-router-dom";
import { Button, InputGroup } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClockRotateLeft,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import Loader from "../Loader/Loader";

import { updateEmployeeStatus } from "../../utils/api";

export default function ItemEmploye(props) {
  // console.log("PROPS", props);
  const [item, setItem] = useState(props.item);
  const [loader, setLoader] = useState(false);

  const toogleEmploye = () => {
    setLoader(true);
    updateEmployeeStatus(item.id, {})
      .then((respEmployee) => {
        console.log("respEmployee", respEmployee);
        setItem((pS) => ({ ...pS, status: pS.status === "1" ? "0" : "1" }));
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <li>
      <p>
        {item.first_name} {item.last_name}
      </p>
      <div>
        {loader ? (
          <Loader />
        ) : (
          <InputGroup size="lg">
            <Button
              variant={item.status === "1" ? "success" : "danger"}
              onClick={toogleEmploye}
            >
              <FontAwesomeIcon icon={item.status === "1" ? faCheck : faXmark} />
            </Button>
          </InputGroup>
        )}
      </div>
    </li>
  );
}
