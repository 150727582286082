import React from "react";
import { Col, Row, InputGroup, Form } from "react-bootstrap";

import { COLORS } from "../../../config";

export default function ManagerInfo({ managerState, handleTextField, countData }) {
  return (
    <Row className="">
      <Col xs={12} className="mt-5">
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Expected Number of employees:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="text"
            style={{ color: COLORS.DinamicLabel, textAlign: "right" }}
            data-handle1="expectedNumerOfEmployees"
            onChange={handleTextField}
            value={managerState.expectedNumerOfEmployees}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Pounds of Potatoes on hand:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="number"
            style={{ color: COLORS.DinamicLabel, textAlign: "right" }}
            data-handle1="poundsOfPotatoesOnHand"
            onChange={handleTextField}
            value={managerState.poundsOfPotatoesOnHand}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Batches to be seasoned:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="text"
            style={{ color: COLORS.DinamicLabel, textAlign: "right" }}
            value={countData.batchesToBeSeasoned}
            readOnly
            disabled
          />
        </InputGroup>
      </Col>     
    </Row>
  );
}
