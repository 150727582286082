import React, { useState } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

import { Container, Navbar, Nav, Offcanvas } from "react-bootstrap";

import { faBurger } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useToken from "./useToken";
import useUser from "./useUser";

import { routes, publicRoutes } from "./routes";
import Manager_Page from "../Manager_Page";

// import 'bootstrap/dist/css/bootstrap.min.css';

const routesEmployee = ["#/seasoning", "#/raw", "#/fryer", "#/qa", "#/potatostorage", "#/finishedgoods"];

const routesDashboard = [
  "#/dashboard-estate-inventory",
  "#/dashboard-limted-edition-inventory",
  "#/fulfillment-priority",
];

function App() {
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);

  const currentTime = new Date();

  if (!token || !user || !user.id) {
    return (
      <HashRouter>
        <Routes>
          {publicRoutes.map((r) => (
            <Route key={r.href} path={`/${r.href}`} element={r.component} exact={true} />
          ))}
        </Routes>
        <Navbar expand="md" fixed="bottom" className="shadow-sm bg-light">
          <Container>
            <Navbar.Text>
              <small>© WineChips 2022-{currentTime.getFullYear()}</small>
            </Navbar.Text>
          </Container>
        </Navbar>
      </HashRouter>
    );
  }

  let isDashboard = routesDashboard.includes(window.location.hash);
  let isEmployee = routesEmployee.includes(window.location.hash);

  return (
    <HashRouter>
      <Container id="header" style={{ position: "fixed", backgroundColor: "#fff", zIndex: 10, maxWidth: "100%" }}>
        <Navbar.Brand
          href="/"
          id="linkUser"
          className="d-flex align-items-center"
          onClick={(e) => {
            e.preventDefault();
            // if (isEmployee) {
            //   return;
            // }
            handleShowOffcanvas();
          }}
          style={{ justifyContent: "center" }}
        >
          <FontAwesomeIcon icon={faBurger} style={{ width: 30, height: 30 }} size="lg" />
          <img
            src="/logo-wide.png"
            alt="logo-wide"
            id="logoImg"
            className="mx-auto py-2 my-2"
            style={{ maxHeight: 50 }}
          />
        </Navbar.Brand>
      </Container>
      {/* <Navbar id="nav_1" expand="md" fixed="top" className="shadow-sm sticky-top bg-light" collapseOnSelect>
        
      </Navbar> */}
      <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas}>
        <Offcanvas.Header
          // closeButton
          onClick={handleCloseOffcanvas}
          style={{ padding: ".5rem 1.5rem" }}
          className="mt-2"
        >
          <Offcanvas.Title>
            <img src="/logo-wide.png" height="24" alt="logo-wide" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body
          style={{
            padding: ".5rem .5rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <div style={{ maxHeight: "calc(100% - 50px)", overflow: "auto" }}>
            <Nav style={{ flexDirection: "column" }}>
              {routes
                .filter((r) => !r.href.includes("fulfillment-priority"))
                .map((n) => {
                  let hrToUse = n.href;
                  if (hrToUse === "fulfillment-priority/:page") {
                    hrToUse = n.href.replace(":page", "1");
                  }
                  return (
                    <Nav.Link
                      href={`#/${hrToUse}`}
                      onClick={handleCloseOffcanvas}
                      key={n.href}
                      className="nav-link custom"
                    >
                      {n.title}
                    </Nav.Link>
                  );
                })}
              <Nav.Link active={true} href={`#/fulfillment-priority/1`} onClick={handleCloseOffcanvas}  className="nav-link custom">
                {"Dashboard Orders 1"}
              </Nav.Link>
              <Nav.Link href={`#/fulfillment-priority/2`} onClick={handleCloseOffcanvas} className="nav-link custom">
                {"Dashboard Orders 2"}
              </Nav.Link>
            </Nav>
          </div>

          <Nav style={{ borderTop: "2px solid #0a0a0a", width: "100%" }}>
            <Nav.Link
              href="/"
              className="custom"
              onClick={(e) => {
                setToken({ token: null });
                setUser({});
              }}
            >
              Log out
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
      <Container className="" style={{ paddingTop: 70 }}>
        <Routes>
          {routes.map((r) => (
            <Route key={r.href} path={`/${r.href}`} element={r.component} />
          ))}
          <Route path="/" element={<Manager_Page />} />
        </Routes>
      </Container>
      <Navbar id="nav_2_footer" expand="md" fixed="bottom" className="shadow-sm bg-light">
        <Container>
          <Navbar.Text>
            <small>© WineChips 2022-{currentTime.getFullYear()}</small>
          </Navbar.Text>
        </Container>
      </Navbar>
    </HashRouter>
  );
}

export default App;
