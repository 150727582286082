import React, { useCallback, useRef, useState } from "react";

import { Badge, Button, Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import TagsInput from "react-tagsinput";

import Loader from "../Loader/Loader";

import { updateItem, getItemHistory } from "../../utils/api";

export default function ItemFulfillment(props) {
  const tagsRef = useRef();
  // console.log("PROPS", props);
  const [item, setItem] = useState(props.data[props.index]);
  const [received, setReceived] = useState();

  const [history, setHistory] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(false);

  function onUpdateItem(e, special, id_special) {
    let id = "";
    let value = "";
    let name = "";

    if (special) {
      id = id_special;
      value = e;
      name = special;
    } else {
      id = e.target.id;
      value = e.target.value;
      name = e.target.name;
    }

    updateItem(id, { [name]: value }).then((r) => {
      console.log(r);
      tagsRef.current.clearInput();
    });
  }

  const debounceFn = useCallback(debounce(onUpdateItem, 1000), []);

  const onChangeItem = (e) => {
    const { value, name } = e.target;
    setItem((pS) => ({ ...pS, [name]: value }));
    debounceFn(e);
  };

  const onChangeReceived = (e) => {
    updateItem(item.id, { "factory_diff": received });
    setItem((pS) => ({ ...pS, "Quantity On Hand": ((+pS["Quantity On Hand"]) + (+received)) }));
    setTimeout(() => setReceived(0));
  };

  const onHistoryItem = (e, id) => {
    // e.preventDefault();
    setHistoryLoading(true);
    getItemHistory(id).then((r) => {
      if (r && r.length) {
        setHistory(r);
      } else {
        setHistory([]);
      }
      setHistoryLoading(false);
    });
  };

  const onChangeItemLocationTags = (e) => {
    setItem((pS) => ({ ...pS, location: e }));
    debounceFn(e.join(" "), "location", item.id);
  };

  let location = [];

  if (item["location"] && typeof item["location"] === "string") {
    location = item["location"].split(" ").map((d) => d.trim());
  }

  if (item["location"] && Array.isArray(item["location"])) {
    location = item["location"];
  }

  return (
    <>
      <ul key={item.id} className="rowItem" style={props.style}>
        <li key={"Item"}>
          <p>{item["Item"]}</p>
        </li>
        <li key={"Description"}>
          <p>{item["Description"]}</p>
        </li>
        <li key={"Quantity"} className="text-center">
          <p>{+item["Quantity On Hand"] + (+item["factory_acc_diff"]) + (+item["shop_acc_diff"])}</p>
        </li>
        <li className="text-primary" key={"Received"}>
          <div>
            <InputGroup size="sm">
              <Form.Control
                type="number"
                step="1"
                // name="factory_diff"
                // value={item["Received Quantity"]}
                value={received}
                id={item.id}
                onChange={(e) => setReceived(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" ? onChangeReceived() : false}
              />
              <Button
                variant="outline-secondary"
                onClick={onChangeReceived}
                title="Received Quantity Adjustment"
              >
                {/* <FontAwesomeIcon icon={faCheck} /> */}
                Add
              </Button>
            </InputGroup>
          </div>
        </li>
        <li key={"Sold"} className="text-center">
          <p>{+item["shop_acc_diff"]}</p>
        </li>
        <li key={"history"}>
          <div className="historyCell">
            <DropdownButton
              as={InputGroup}
              size="sm"
              variant="outline-secondary"
              title={<FontAwesomeIcon icon={faClockRotateLeft} />}
              onToggle={(isOpen, e, metadata) => {
                if (isOpen) {
                  onHistoryItem(e, item.id);
                }
              }}
            >
              {historyLoading ? (
                <Loader />
              ) : history && history.length ? (
                history.map((hItem) => (
                  <Dropdown.Item key={hItem["updated"]}>
                    {["updated", "Quantity On Hand", "factory_diff", "shop_diff", "login"].map((pr) => (
                      <Badge key={pr} bg="secondary" className="my-1 mx-1">
                        {hItem[pr] || '-'}
                      </Badge>
                    ))}
                  </Dropdown.Item>
                ))
              ) : (
                <Dropdown.Item>Empty history</Dropdown.Item>
              )}
            </DropdownButton>
          </div>
        </li>
        <li key={"updated"}><small>{item.updated}</small></li>
        <li className="text-primary" key={"location"}>
          <TagsInput
            ref={tagsRef}
            value={location}
            onChange={onChangeItemLocationTags}
            onlyUnique={true}
            addOnBlur
            onChangeInput={() => {
              console.log("onChangeInput");
            }}
            tagProps={{ className: "react-tagsinput-tag custom" }}
          />
        </li>
      </ul>
    </>
  );
}
