import React from "react";
import { Col, Form, InputGroup, Button, Row, Badge } from "react-bootstrap";

import { COLORS, CODE_FLAVOR } from "../../../../config";

export default function TabData({ handleChange, state }) {
  const flavor = CODE_FLAVOR.find(({ code }) => code === state.flavor);

  return (
    <>
      <Row>
        <Col xs={7}>
          <div className="fs_c_40">
            <span className="label_color">
              {flavor?.name}{" "}
              {(state.flavor === "00" && <span className="label_color">{`(${state.customFlavor})`}</span>) || "test"}
            </span>
          </div>
        </Col>
        <Col xs={5}>
          <div className="fs_c_40 mb-3">
            Bag Size: <span className="label_color">{state.bag_size}</span>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={6}>
          <div className="fs_c_30 mb-1">
            Estimated Bags: <span className="label_color">{state.estimatedNumberOfBags}</span>
          </div>
          <div className="fs_c_30 mb-1">
            Singles: <span className="label_color">{state.singles}</span>
          </div>
          <div className="fs_c_30 mb-1">
            Refills: <span className="label_color">{state.refills}</span>
          </div>
        </Col>
        <Col xs={6}>
          <div className="fs_c_30 mb-1">
            Extra Bags: <span className="label_color">{state.extraBags}</span>
          </div>
          <div className="fs_c_30 mb-1">
            Less Bags: <span className="label_color">{state.lessBags}</span>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={6}>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_20">Flavor Start Time:</InputGroup.Text>
            <Form.Control
              className="fs_c_30"
              type="time"
              aria-label="Start Time"
              style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
              onChange={handleChange}
              data-handle1="flavor_start_time"
              data-handle2={state.id}
              value={state.flavor_start_time}
            />
            <Button
              data-handle1="flavor_start_time"
              data-handle2={state.id}
              data-handle4="clear"
              onClick={handleChange}
              style={{ padding: "0 5px" }}
            >
              Clear
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mb-4">
        <div className="fs_c_30 mb-3 text-center">Singles Completed</div>
        <div className="d-flex flex-row align-items-center justify-content-center">
          <Button
            variant="primary"
            size="lg"
            className="mr-1 fs_c_100"
            data-handle1="singles_completed"
            data-handle2={state.id}
            data-handle3="plus"
            style={{ marginRight: 30 }}
            onClick={handleChange}
          >
            +50
          </Button>

          <Button
            variant="primary"
            size="lg"
            className="fs_c_100"
            onClick={handleChange}
            data-handle1="singles_completed"
            data-handle2={state.id}
            data-handle3="minus"
            style={{ paddingRight: 30, paddingLeft: 30 }}
          >
            -
          </Button>
          <Badge bg="#EE476F" className="fs_c_100">
            {state.singles_completed}
          </Badge>
        </div>
      </Row>
      <Row className="mb-3">
        <div className="fs_c_30 mb-3 text-center">Refills Completed</div>
        <div className="d-flex flex-row align-items-center justify-content-center">
          <Button
            variant="primary"
            size="lg"
            className="mr-1 fs_c_100"
            style={{ marginRight: 30 }}
            data-handle1="refills_completed"
            data-handle2={state.id}
            data-handle3="plus"
            onClick={handleChange}
          >
            +
          </Button>
          <Button
            variant="primary"
            size="lg"
            className="fs_c_100"
            onClick={handleChange}
            data-handle1="refills_completed"
            data-handle2={state.id}
            data-handle3="minus"
            style={{ paddingRight: 30, paddingLeft: 30 }}
          >
            -
          </Button>
          <Badge bg="#EE476F" className="fs_c_100">
            {state.refills_completed}
          </Badge>
        </div>
      </Row>

      <Row className="justify-content-center">
        <Col xs={6}>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_20">Were all Bags Kitted:</InputGroup.Text>
            <Form.Select
              onChange={handleChange}
              data-handle1="were_all_bags_kitted"
              data-handle2={state.id}
              value={state.were_all_bags_kitted}
              className="fs_c_20"
              style={{ color: COLORS.Dropdown }}
            >
              {["YES", "NO"].map((em) => (
                <option key={em} value={em}>
                  {em}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs={6}>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_20">Number Of Bags Left To Kit:</InputGroup.Text>
            <Form.Control
              className="fs_c_30"
              type="number"
              style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
              onChange={handleChange}
              data-handle1="number_of_bags_left_to_kit"
              data-handle2={state.id}
              value={state.number_of_bags_left_to_kit}
            />
          </InputGroup>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs={6}>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_20">Flavor End Time:</InputGroup.Text>
            <Form.Control
              className="fs_c_30"
              type="time"
              style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
              onChange={handleChange}
              data-handle1="flavor_end_time"
              data-handle2={state.id}
              value={state.flavor_end_time}
            />
            <Button
              data-handle1="flavor_end_time"
              data-handle2={state.id}
              data-handle4="clear"
              onClick={handleChange}
              style={{ padding: "0 5px" }}
            >
              Clear
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </>
  );
}
