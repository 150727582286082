import React from "react";
import { Row, Col, InputGroup, Button, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCheck } from "@fortawesome/free-solid-svg-icons";
import { openStationShift, closeStationShift } from "../utils/api";

export function ShiftManage({ createShift, shiftState, setShiftState, handleChange, isStationActive, deleteShift }) {
  const openShift = (e) => {
    e.preventDefault();
    const name = e.target.name;
    openStationShift(name, {})
      .then((r) => {
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const closeShift = (e) => {
    e.preventDefault();
    const name = e.target.name;
    closeStationShift(name, {})
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      {createShift && <Row>
        <Col xs={8}>
          <InputGroup className="mb-4">
            <InputGroup.Text className="fs_c_30">Create New Shift:</InputGroup.Text>
            <Button variant="outline-primary" size="lg" className="mr-1" onClick={createShift}>
              <span className="fs_c_50">+</span>
            </Button>
          </InputGroup>
        </Col>
        <Col xs={4}>
          <Form.Check
            type="switch"
            reverse
            id="custom-switch"
            label="ACTIVE"
            data-handle1="isStationActive"
            onChange={handleChange}
            style={{ fontSize: 50 }}
            checked={isStationActive}
            disabled={shiftState?.list.length === 0}
          />
        </Col>
      </Row>}
      <Row className="justify-content-center  mb-3">
        <Col xs={12}>
          {shiftState.list.length === 0 && <p>No One Shifts Created</p>}

          {shiftState.list.length > 0 && (
            <Table bordered hover>
              <thead>
                <tr>
                  {["in Edit", "shift", "started", "closed", ""].map((th) => (
                    <th style={{ textAlign: "center", fontSize: 25 }} key={th}>
                      {th}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {shiftState.list.map((st, ind) => (
                  <tr key={st.id}>
                    <td
                      style={{ width: "10%", fontSize: 25 }}
                      onClick={() => {
                        setShiftState((pS) => ({
                          ...pS,
                          activeShift: st.shift,
                        }));
                      }}
                    >
                      {st.shift === shiftState.activeShift && (
                        <FontAwesomeIcon icon={faCheck} size="xl" style={{ color: "#00FF00", padding: "10px 20px" }} />
                      )}
                    </td>
                    <td className="fs_c_40 text-center">{st.shift}</td>

                    <td className="fs_c_20 text-center">
                      {st.start ? (
                        st.start
                      ) : (
                        <Button
                          type="submit"
                          size="lg"
                          className="fs_c_30"
                          style={{ margin: "0 auto" }}
                          name={st.name}
                          onClick={openShift}
                        >
                          Force Open
                        </Button>
                      )}
                    </td>

                    <td className="fs_c_20 text-center">
                      {st.end ? (
                        st.end
                      ) : (
                        <Button
                          type="submit"
                          size="lg"
                          className="fs_c_30"
                          style={{ margin: "0 auto" }}
                          name={st.name}
                          onClick={closeShift}
                        >
                          Force Close
                        </Button>
                      )}
                    </td>
                    <td
                      onClick={() => {
                        if (!st.end && !st.start) {
                          deleteShift(st.id);
                        }
                      }}
                      style={{ textAlign: "center", fontSize: 25, width: "15%" }}
                    >
                      <FontAwesomeIcon icon={faTrash} size="xl" style={{ color: "#ff0000", padding: 10 }} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </>
  );
}
