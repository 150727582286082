import React, { useState, useEffect } from "react";
import { Table, InputGroup, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

import { getMinutesFromInput } from "../../utils/utils";

import { COLORS, HEAD_TABLE_EMPLOYEES } from "../../config";

const tamplateActiveEmployee = { id: "", value: "", name: "", in: "", out: "", minutes: 0, status: "", notes: "" };

const statuses = ["Break", "Close Station", "Pause Station (add explanation in notes)", "Lunch"];

export default function PopupActiveEmployee({
  asBlock,
  employeesListFromDB,
  employeesFullList,
  closeModal,
  handleSaveDataFromPopups,
}) {
  // console.log(employeesListFromDB, employeesFullList);
  const [employeesList, setEmployeesList] = useState(employeesListFromDB || []);
  const [popupNotes, setPopupNotes] = useState(null);

  useEffect(() => {
    setEmployeesList(employeesListFromDB);
  }, [employeesListFromDB]);

  const handleChange = ({ target }) => {
    let attr1 = target.getAttribute("data-handle1") || target.parentNode.getAttribute("data-handle1");
    let attr2 = target.getAttribute("data-handle2") || target.parentNode.getAttribute("data-handle2");
    let attr3 = target.getAttribute("data-handle3") || target.parentNode.getAttribute("data-handle3");

    let value = target.value;

    // console.log(attr1, attr2, value, employeesList, );

    if (attr1 === "add_new") {
      const getEmpl = employeesFullList.find((em) => +em.value === +value);

      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      const newActEmpl = {
        ...tamplateActiveEmployee,
        ...getEmpl,
        id: `${getEmpl.value}_${employeesList?.length}`,
        in: `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`,
      };

      setEmployeesList((pS) => [...pS, newActEmpl]);
    }
    if (attr1 === "delete") {
      setEmployeesList((pS) => pS.filter((emp) => emp.id !== attr2));
    }
    if (attr1 === "in" || attr1 === "out") {
      setEmployeesList((pS) =>
        pS.map((emp) => {
          if (emp.id === attr2) {
            const updatedEmp = { ...emp, [attr1]: attr3 === "clear" ? "" : value };
            return { ...updatedEmp, minutes: getMinutesFromInput(updatedEmp.in, updatedEmp.out) };
          }
          return emp;
        })
      );
    }
    if (attr1 === "status") {
      if (value === "Pause Station (add explanation in notes)") {
        let indexEl = 0;
        for (let i = 0; i < employeesList.length; i++) {
          const element = employeesList[i];
          if (element.id === attr2) {
            indexEl = i;
            break;
          }
        }

        setPopupNotes({ id: attr2, index: indexEl });
      }
      setEmployeesList((pS) =>
        pS.map((emp) => {
          if (emp.id === attr2) {
            return { ...emp, [attr1]: value };
          }
          return emp;
        })
      );
    }
    if (attr1 === "notes") {
      setEmployeesList((pS) =>
        pS.map((emp) => {
          if (emp.id === attr2) {
            return { ...emp, [attr1]: value };
          }
          return emp;
        })
      );
    }
  };

  const totalMinutes = employeesList && employeesList.reduce((acc, v) => acc + +v.minutes, 0).toFixed(0);

  const saveChanges = () => {
    handleSaveDataFromPopups("activeEmployee", employeesList);
    !asBlock && closeModal();
  };

  return (
    <div className={asBlock ? "" : "popup_container"}>
      <div className={`block ${asBlock ? "daily" : ""}`}>
        {!asBlock && (
          <div className="close_button" onClick={closeModal}>
            <FontAwesomeIcon icon={faCircleXmark} size="2xl" style={{ color: "#511f2e", padding: 10 }} />
          </div>
        )}
        <div className="fs_c_30 text-center">Active Employee</div>

        <Table bordered hover>
          <thead>
            <tr>
              {HEAD_TABLE_EMPLOYEES.map((th) => (
                <th style={{ textAlign: "center", fontSize: 25 }} key={th}>
                  {th}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {employeesList &&
              employeesList.map((s, index) => {
                return (
                  <tr key={s.id + index}>
                    <td style={{ textAlign: "center", fontSize: 25, lineHeight: 2, width: "25%" }}>{s.name}</td>
                    <td style={{ width: "20%" }}>
                      <InputGroup>
                        <Form.Control
                          className="fs_c_20"
                          type="time"
                          style={{ color: COLORS.Date_timePicker, textAlign: "start" }}
                          data-handle1="in"
                          data-handle2={s.id}
                          onChange={handleChange}
                          value={s.in}
                        />
                        <Button
                          data-handle1="in"
                          data-handle2={s.id}
                          data-handle3="clear"
                          onClick={handleChange}
                          style={{ padding: "0 5px" }}
                        >
                          Clear
                        </Button>
                      </InputGroup>
                    </td>
                    <td style={{ width: "20%" }}>
                      <InputGroup>
                        <Form.Control
                          className="fs_c_20"
                          type="time"
                          style={{ color: COLORS.Date_timePicker, textAlign: "start" }}
                          data-handle1="out"
                          data-handle2={s.id}
                          onChange={handleChange}
                          value={s.out}
                        />
                        <Button
                          data-handle1="out"
                          data-handle2={s.id}
                          data-handle3="clear"
                          onClick={handleChange}
                          style={{ padding: "0 5px" }}
                        >
                          Clear
                        </Button>
                      </InputGroup>
                    </td>
                    <td style={{ textAlign: "center", fontWeight: "bolder", fontSize: 30, width: "22%" }}>
                      <Form.Select
                        onChange={handleChange}
                        data-handle1="status"
                        data-handle2={s?.id}
                        className="fs_c_20 mb-1"
                        style={{ color: COLORS.Dropdown, textAlign: "right" }}
                        value={s?.status}
                      >
                        {statuses.map((em) => (
                          <option key={em} value={em}>
                            {em}
                          </option>
                        ))}
                      </Form.Select>
                    </td>
                    <td style={{ textAlign: "center", fontWeight: "bolder", fontSize: 25 }}>{s.minutes}</td>
                    <td
                      data-handle1="delete"
                      data-handle2={s.id}
                      onClick={handleChange}
                      style={{ textAlign: "center", fontSize: 14 }}
                    >
                      <FontAwesomeIcon
                        data-handle1="delete"
                        data-handle2={s.id}
                        icon={faTrash}
                        size="xl"
                        style={{ color: "#ff0000", padding: 5 }}
                      />
                    </td>
                    <td
                      onClick={() => {
                        if (s.status === "Pause Station (add explanation in notes)") {
                          setPopupNotes({ id: s.id, index: index });
                        }
                      }}
                      style={{ textAlign: "center", fontSize: 14 }}
                    >
                      {s.status === "Pause Station (add explanation in notes)" ? (
                        <FontAwesomeIcon icon={faEdit} size="xl" style={{ color: "#0000aa", padding: 5 }} />
                      ) : (
                        <div>__</div>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>

        {popupNotes && (
          <div className="notes">
            <InputGroup className="mb-3">
              <Form.Control
                className="fs_c_20"
                type="text"
                style={{ color: COLORS.Date_timePicker, textAlign: "start" }}
                data-handle1="notes"
                data-handle2={popupNotes.id}
                onChange={handleChange}
                value={employeesList[popupNotes.index].notes}
              />
              <div
                onClick={() => {
                  setPopupNotes(null);
                }}
              >
                <FontAwesomeIcon icon={faCircleXmark} size="2xl" style={{ color: "#511f2e", padding: 10 }} />
              </div>
            </InputGroup>
          </div>
        )}

        <div className="total_minutes">
          <span>{totalMinutes}</span> Total Minutes
        </div>

        <Form.Select
          onChange={handleChange}
          data-handle1="add_new"
          className="fs_c_20"
          style={{ color: COLORS.Dropdown }}
        >
          <option style={{ fontSize: 12, color: "gray" }}>Select Employee</option>
          {employeesFullList &&
            employeesFullList.map((em) => (
              <option key={em.value} value={em.value}>
                {em.name}
              </option>
            ))}
        </Form.Select>

        {/* {!asBlock && ( */}
        <Button
          size="lg"
          className={asBlock ? "" : `save_button mt-3`}
          style={{ padding: "10px 20px" }}
          onClick={saveChanges}
        >
          Save Active Employees
        </Button>
        {/* )} */}
      </div>
    </div>
  );
}
